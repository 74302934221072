import React, { Fragment } from 'react';

const Education = () => {
	return (
		<Fragment>
			<div id="about" className="section py-6 py-md-7 bg-white">
				<div className="container">
					<div className="row justify-content-between">
						<div className="col-lg-12" data-aos="flip-up">
							<div className="py-0">
								<h1 className="fw-bold mb-3">Education</h1>
								<p>BotScheduling is a game-changer in the education sector, offering innovative solutions for a wide range of activities. From scheduling lessons, academic tutoring sessions, and teacher consultations to coordinating school tours and parent-teacher conferences, BotScheduling streamlines the educational experience. It enables 24/7 booking, reduces scheduling conflicts, and enhances communication between educators, students, and parents. Whether it's booking a piano class, arranging a math tutoring session, or coordinating a virtual school tour, BotScheduling empowers educational institutions to optimize their operations and focus on delivering enriching learning opportunities</p>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div id="about" className="section py-6 py-md-7 bg-light">
				<div className="container">
					<div className="row justify-content-between">
						<div className="col-lg-12" data-aos="flip-up">
							<div className="py-0">
								<h2 className="fw-bold mb-3">Education Benefits</h2>
								<ul>
									<li>Effortlessly schedule lessons, tutoring sessions, and teacher consultations.</li>
									<li>Streamline school tours and parent-teacher conferences.</li>
									<li>Enable 24/7 booking convenience for students and parents.</li>
									<li>Reduce scheduling conflicts with automated appointment management.</li>
									<li>Enhance communication among educators, students, and parents.</li>
									<li>Optimize school operations, allowing educators to focus on enriching learning opportunities.</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	)
}

export default Education;