import React, { Fragment, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import Header from './fragments/Header'
import Footer from './fragments/Footer'

const Contact = () => {
	const [displaySalesForm, setDisplaySalesForm] = useState(false);
	const navigate = useNavigate();

	const handleTechSupportEvent = () => {
		setDisplaySalesForm(false);
	}

	const handleClickEvent = (type: string) => {
		switch (type) {
			case 'sales':
				setDisplaySalesForm(true);
				break;
			case 'login':
				window.location.href = 'https://app.botscheduling.com/auth/login';
				break;
			case 'docs':
				navigate('/docs');
				break;
		}
	}

	return (
		<Fragment>
			<Header forceLightMenu={true} />
			<main id="content">
				<div style={{height: "92px"}} />

				{/* =========={ CONTACT }==========  */}
				<div id="contact" className="section py-6 bg-light-dark">

					{!displaySalesForm ?
						<div className="container">
							<header className="text-center mx-auto mb-5">
								<h2 className="h2 fw-bold">Contact Us</h2>
								<hr className="divider my-4 bg-warning border-warning"/>
							</header>
							<div className="row">
								<div className="col d-flex flex-column text-center">
									<h2>Sales Assistance</h2>
									<p>Need help with sales or have questions about our services? Contact us today to
										learn more about how BotScheduling can revolutionize your scheduling
										process!</p>
									<div className="mt-auto">
										<button type="button" className="btn btn-pill btn-secondary text-uppercase"
										        onClick={() => handleClickEvent('sales')}>Sales Support
										</button>
									</div>
								</div>
								<div className="col d-flex flex-column text-center">
									<h2>Technical Support</h2>
									<p>For technical support with your workspace, login to your workspace and locate
										"tickets" under your profile. Create a new ticket and our bots will be ready to
										assist!</p>
									<div className="mt-auto">
										<button type="button" className="btn btn-pill btn-secondary text-uppercase"
										        onClick={() => handleClickEvent('login')}>Login
										</button>
									</div>
								</div>
								<div className="col d-flex flex-column text-center">
									<h2>Docs</h2>
									<p>Explore our Docs to help answer some general questions or maybe even learn
										something you didn't know about BotScheduling!</p>
									<div className="mt-auto">
										<button type="button" className="btn btn-pill btn-secondary text-uppercase"
										        onClick={() => handleClickEvent('docs')}>Take me to Docs
										</button>
									</div>
								</div>
							</div>
						</div>
						:
						<div className="container">
							{/* Row */}
							<div className="row g-0">
								<div className="col-lg-6 col-xl-5">
									{/* header banner */}
									<div className="position-relative header-banner z-index-1">
										{/* background overlay */}
										<div className="overlay bg-secondary opacity-90 z-index-n1"/>
									{/* banner content */}
									<div className="header-banner-content">
										<div className="mb-4 px-lg-5">
											<h3 className="text-light">Got Questions?</h3>
											<hr className="divider my-4 bg-light border-light"/>
										</div>
										<div className="text-light mx-6">
											<p>Need assistance with sales or have questions about our services? Contact us today to learn more about how BotScheduling can revolutionize your scheduling process.</p>
										</div>
									</div>
								</div>
								{/* end header banner */}
							</div>
							<div className="col-lg-6 col-xl-7">
								<div className="p-4 p-xl-5 bg-body shadow-sm">
									<div className="contact-area w-100 p-4">
										<h3 className="mb-5 text-center">Contact Us</h3>
										{/* contact form */}
										<form action="#" className="contact-form">
											<div className="row">
												<div className="col-md-6 mb-4">
													<label className="form-label" htmlFor="name">Your Name</label>
													<input type="text" name="name" className="form-control" id="name"/>
													<div className="validate"/>
												</div>
												<div className="col-md-6 mb-4">
													<label className="form-label" htmlFor="email">Your Email</label>
													<input type="email" className="form-control" name="email"
													       id="email"/>
													<div className="validate"/>
												</div>
											</div>
											<div className="mb-4">
												<label className="form-label" htmlFor="subject">Subject</label>
												<input type="text" className="form-control" name="subject"
												       id="subject"/>
												<div className="validate"/>
											</div>
											<div className="mb-4">
												<label className="form-label" htmlFor="messages">Message</label>
												<textarea className="form-control" name="message" rows={10}
												          id="messages" defaultValue={""}/>
												<div className="validate"/>
											</div>
											<div className="text-center mb-4">
												<button className="btn btn-pill btn-warning" type="submit">
													<svg xmlns="http://www.w3.org/2000/svg" width="1.5rem"
													     height="1.5rem" fill="currentColor" className="me-2 rtl-270"
													     viewBox="0 0 512 512">
														<path
															d="M53.12,199.94l400-151.39a8,8,0,0,1,10.33,10.33l-151.39,400a8,8,0,0,1-15-.34L229.66,292.45a16,16,0,0,0-10.11-10.11L53.46,215A8,8,0,0,1,53.12,199.94Z"
															style={{
																fill: 'none',
																stroke: 'currentColor',
																strokeLinecap: 'round',
																strokeLinejoin: 'round',
																strokeWidth: '32px'
															}}/>
														<line x1={460} y1={52} x2={227} y2={285} style={{
															fill: 'none',
															stroke: 'currentColor',
															strokeLinecap: 'round',
															strokeLinejoin: 'round',
															strokeWidth: '32px'
														}}/>
													</svg>
													SEND
												</button>
											</div>
										</form>
										{/* end contact form */}
									</div>
								</div>
							</div>
							<div className="text-center mt-4">
								<button className="btn btn-pill btn-secondary text-uppercase" onClick={() => handleTechSupportEvent()}>Need technical support?</button>
							</div>
						</div>
					</div>
					}
				</div>
			</main>
			<Footer/>
		</Fragment>
	)
};

export default Contact;