import React, { Fragment } from 'react';
import { useParams } from "react-router-dom";

import Header from './fragments/Header';
import Footer from './fragments/Footer';

import HealthMindAndBody from './industries/HealthMindAndBody';
import Education from './industries/Education';
import ProfessionalServices from './industries/ProfessionalServices';
import TradeBusiness from './industries/TradeBusiness';
import General from "./industries/General";

const Industries = () => {
	const { title } = useParams();

	const getContent = (title: string|undefined) => {
		switch (title) {
			case 'health-and-beauty':
				return <HealthMindAndBody />;
			case 'education':
				return <Education />;
			case 'professional-services':
				return <ProfessionalServices />;
			case 'trade-business':
				return <TradeBusiness />;
			default:
				return <General />;
		}
	}

	return (
		<Fragment>
			<Header forceLightMenu={true} />

			<main id="content">
				<div style={{height: "92px"}} />

				{getContent(title)}
			</main>

			<Footer />
		</Fragment>
	)
}

export default Industries;