import React, { Fragment } from 'react';
import Header from './fragments/Header'
import Footer from './fragments/Footer'

const Features = () => {
	return (
		<Fragment>
			<Header forceLightMenu={true} />

			<main id="content">
				<div style={{height: "92px"}} />

				{/* =========={ Scalable }==========  */}
				<div id="about" className="section py-6 py-md-7 bg-white">
					<div className="container">
						{/* row */}
						<div className="row justify-content-between">
							<div className="col-lg-12" data-aos="flip-up">
								<div className="py-0">
									<h2 className="fw-bold mb-3">Scalable</h2>
									<p className="mb-5">When considering scheduling software, adaptability to your business's evolution is key. BotScheduling provides exactly that through its scalable infrastructure. Whether you operate with a small or large team, our solution is tailored to fit your unique needs, accommodating changes and growth effectively.</p>
								</div>
							</div>
							<div className="col-lg-12" data-aos="fade-right">
								{/* About 2 Thumb */}
								<div className="text-center">
									<div className="m-0p-6">
										<img className="img-fluid" src="src/logo/Scalable.png" alt="Scalable" />
									</div>
								</div>
							</div>
						</div>{/* end row */}
					</div>
				</div>{/* End About */}

				{/* =========={ Notifications }==========  */}
				<div id="about" className="section showcase text-white bg-secondary">
					<div className="container-fluid p-0">
						<div className="row g-0">
							{/* img block */}
							{/*<div className="col-lg-6 order-lg-2 text-white showcase-img" style={{backgroundImage: 'url("src/img-min/section/app-desktop2.jpg")'}} data-aos="fade-left" />*/}
							{/* content block */}
							<div className="col-lg-12 order-lg-1 my-auto showcase-text" data-aos="fade-right">
								<div className="p-5 p-md-7">
									<h2 className="fw-bold text-white mb-3">Notifications</h2>
									<p className="">BotScheduling's notifications feature stands as a reliable bridge between you and your customers. Automated notifications ensure essential communication like appointment confirmations, reminders, cancellations, and schedule changes. This feature streamlines engagement, keeping your customers informed and connected at every step of their interaction with your services.</p>
								</div>
							</div>
						</div>
					</div>
				</div>{/* end about */}

				{/* =========={ Reporting }==========  */}
				<div id="about" className="section py-6 py-md-7 bg-white">
					<div className="container">
						{/* row */}
						<div className="row justify-content-between">
							<div className="col-lg-6 col-xl-4" data-aos="flip-up">
								<div className="py-0">
									<h2 className="fw-bold mb-3">Reporting</h2>
									<p className="mb-5">Measuring success can be challenging, but with BotScheduling's reporting feature, you're equipped with a convenient tool that simplifies the process. This feature enables you to effortlessly monitor appointment activity, providing you with valuable insights into the individuals and services that are being scheduled. BotScheduling's reporting feature offers you a window into the booking landscape, making it easier to gauge success and make informed decisions.</p>
								</div>
							</div>
							<div className="col-lg-6 col-xl-7" data-aos="fade-right">
								{/* About 2 Thumb */}
								<div className="text-center">
									<div className="mt-lg-5">
										<img className="img-fluid" src="src/logo/Reporting.png" alt="company profile" />
									</div>
								</div>
							</div>
						</div>{/* end row */}
					</div>
				</div>{/* End About */}

				{/* =========={ Location }==========  */}
				<div id="about" className="section py-6 py-md-7 bg-light">
					<div className="container">
						{/* row */}
						<div className="row justify-content-between">
							<div className="col-lg-6" data-aos="fade-right">
								{/* About 2 Thumb */}
								<div className="text-center">
									<div className="px-5 px-md-6 mb-5 mb-lg-0">
										<img className="img-fluid" src="src/logo/Locations.png" alt="Locations" />
									</div>
								</div>
							</div>
							<div className="col-lg-6" data-aos="flip-up">
								<div className="py-0">
									<h2 className="fw-bold mb-3">Location</h2>
									<p className="mb-5">BotScheduling's "Locations" feature truly stands out for its capability to finely subdivide different physical settings, such as multiple storefronts or offices. Users can assign various appointment categories to specific locations, allowing for tailored scheduling that optimizes resource allocation. This versatile customization greatly simplifies operations, resulting in more efficient and personalized scheduling experiences for users.</p>
								</div>
							</div>
						</div>{/* end row */}
					</div>
				</div>{/* End About */}

				{/* =========={ Api Integrations }==========  */}
				<div id="about" className="section py-6 py-md-7 bg-white">
					<div className="container">
						{/* row */}
						<div className="row justify-content-between">
							<div className="col-lg-6" data-aos="flip-up">
								<div className="py-0">
									<h2 className="fw-bold mb-3">API Integration</h2>
									<p className="mb-5">BotScheduling's API integration for developers is a significant leap in appointment scheduling innovation. This feature lets developers effortlessly incorporate BotScheduling's functions into their apps for a tailored scheduling experience. Whether enhancing existing software or creating new solutions, this integration offers a versatile toolkit, redefining how appointment scheduling seamlessly merges with digital platforms, ensuring smoother and more adaptable appointment management.</p>
								</div>
							</div>
							<div className="col-lg-6" data-aos="fade-right">
								{/* About 2 Thumb */}
								<div className="text-center">
									<div className="px-5 px-md-6 mb-5 mb-lg-0">
										<img className="img-fluid" src="src/logo/Integrations.png" alt="Integrations" />
									</div>
								</div>
							</div>
						</div>{/* end row */}
					</div>
				</div>{/* End About */}

				<div className="bg-secondary">
					<svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none"
						 fill="#ffffff"
						 width="200%"
						 height="50px"
						 transform="rotateY(180deg)"
					>
						<path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill" />
					</svg>
				</div>
			</main>

			<Footer />
		</Fragment>
	)
}

export default Features;