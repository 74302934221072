import React, { Fragment } from 'react';
import Header from './fragments/Header'
import Footer from './fragments/Footer'

const Product = () => {
	const svgContent = `
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 394.46 449.99">
			<defs>
				<style>
					.cls-1{fill:none;stroke:#d67e49;stroke-miterlimit:10;stroke-width:2px;opacity:0.25;}
					.cls-2{fill:#60af9e;stroke:none;opacity:0.15;}
				</style>
			</defs>
			<g id="Layer_2" data-name="Layer 2">
				<g id="Layer_1-2" data-name="Layer 1">
					<rect class="cls-1" x="20" y="40" width="150" height="320" rx="10" transform="rotate(-3 300 30)"/>
				</g>
			</g>
			<g id="Layer_2" data-name="Layer 2">
				<g id="Layer_2-2" data-name="Layer 2">
					<rect class="cls-2" x="250" y="50" width="100" height="165" rx="10" transform="rotate(6 300 30)"/>
				</g>
			</g>
		</svg>
	  `;

	const svgContent2 = `
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 394.46 449.99">
			<defs>
				<style>
					.cls2-1{fill:#d15f4f;stroke:none;opacity:0.15;}
					.cls2-2{fill:#ecca65;stroke:none;opacity:0.15;}
					.cls2-3{fill:#36a3c0;stroke:none;opacity:0.15;}
					.cls2-4{fill:#60af9e;stroke:none;opacity:0.15;}
				</style>
			</defs>
			<g id="Layer_2" data-name="Layer 2">
				<g id="Layer_1-2" data-name="Layer 1">
					<rect class="cls2-1" x="180" y="20" width="60" height="110" rx="6" transform="rotate(0 300 30)"/>
				</g>
			</g>
			<g id="Layer_2" data-name="Layer 2">
				<g id="Layer_2-2" data-name="Layer 2">
					<rect class="cls2-2" x="250" y="20" width="60" height="140" rx="6" transform="rotate(0 300 30)"/>
				</g>
			</g>
			<g id="Layer_3" data-name="Layer 3">
				<g id="Layer_3-2" data-name="Layer 3">
					<rect class="cls2-3" x="320" y="20" width="60" height="60" rx="6" transform="rotate(0 300 30)"/>
				</g>
			</g>
			<g id="Layer_4" data-name="Layer 4">
				<g id="Layer_4-2" data-name="Layer 4">
					<rect class="cls2-4" x="320" y="90" width="60" height="60" rx="6" transform="rotate(0 300 30)"/>
				</g>
			</g>
		</svg>
	`;

	const svgContent3 = `
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 394.46 449.99">
			<defs>
				<style>
					.cls3-1{fill:#ecca65;stroke:none;opacity:0.15;}
					.cls3-2{fill:none;stroke:#d15f4f;stroke-miterlimit:10;stroke-width:2px;opacity:0.25;}
					.cls3-3{fill:#36a3c0;stroke:none;opacity:0.15;}
				</style>
			</defs>
			<g id="Layer_3" data-name="Layer 3">
				<g id="Layer_1-3" data-name="Layer 1">
					<rect class="cls3-1" x="90" y="-10" width="80" height="150" rx="6" transform="rotate(-10 300 30)"/>
				</g>
			</g>
			<g id="Layer_3" data-name="Layer 3">
				<g id="Layer_2-3" data-name="Layer 2">
					<rect class="cls3-2" x="150" y="30" width="60" height="160" rx="6" transform="rotate(10 300 30)"/>
				</g>
			</g>
			<g id="Layer_3" data-name="Layer 3">
				<g id="Layer_3-3" data-name="Layer 3">
					<rect class="cls3-3" x="190" y="20" width="70" height="140" rx="6" transform="rotate(0 300 30)"/>
				</g>
			</g>
		</svg>
	`;

	const svgContent4 = `
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 394.46 449.99">
			<defs>
				<style>
					.cls4-2{fill:none;stroke:#36a3c0;stroke-miterlimit:10;stroke-width:2px;opacity:0.25;}
					.cls4-3{fill:none;stroke:#36a3c0;stroke-miterlimit:10;stroke-width:2px;opacity:0.25;}
					.cls4-4{fill:none;stroke:#36a3c0;stroke-miterlimit:10;stroke-width:2px;opacity:0.25;}
				</style>
			</defs>
			<g id="Layer_4" data-name="Layer 4">
				<g id="Layer_2-4" data-name="Layer 2">
					<rect class="cls4-2" x="50" y="100" width="80" height="220" rx="6" transform="rotate(0 300 30)"/>
				</g>
			</g>
			<g id="Layer_4" data-name="Layer 4">
				<g id="Layer_3-4" data-name="Layer 3">
					<rect class="cls4-3" x="150" y="160" width="80" height="160" rx="6" transform="rotate(0 300 30)"/>
				</g>
			</g>
			<g id="Layer_4" data-name="Layer 4">
				<g id="Layer_3-4" data-name="Layer 3">
					<rect class="cls4-4" x="250" y="220" width="80" height="100" rx="6" transform="rotate(0 300 30)"/>
				</g>
			</g>
		</svg>
	`;

	const svgContent5 = `
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 394.46 449.99">
			<defs>
				<style>
					.cls5-1{fill:#ecca65;stroke:none;opacity:0.10;}
					.cls5-2{fill:none;stroke:#d15f4f;stroke-miterlimit:10;stroke-width:2px;opacity:0.15;}
					.cls5-3{fill:#60af9e;stroke:none;opacity:0.10;}
					.cls5-4{fill:none;stroke:#36a3c0;stroke-miterlimit:10;stroke-width:2px;opacity:0.15;}
				</style>
			</defs>
			<g id="Layer_5" data-name="Layer 5">
				<g id="Layer_1-5" data-name="Layer 1">
					<rect class="cls5-1" x="60" y="80" width="60" height="120" rx="6" transform="rotate(10 300 30)"/>
				</g>
			</g>
			<g id="Layer_5" data-name="Layer 5">
				<g id="Layer_2-5" data-name="Layer 2">
					<rect class="cls5-2" x="130" y="60" width="60" height="120" rx="6" transform="rotate(10 300 30)"/>
				</g>
			</g>
			<g id="Layer_5" data-name="Layer 5">
				<g id="Layer_3-5" data-name="Layer 3">
					<rect class="cls5-3" x="200" y="40" width="60" height="120" rx="6" transform="rotate(10 300 30)"/>
				</g>
			</g>
			<g id="Layer_5" data-name="Layer 5">
				<g id="Layer_4-5" data-name="Layer 4">
					<rect class="cls5-4" x="270" y="20" width="60" height="120" rx="6" transform="rotate(10 300 30)"/>
				</g>
			</g>
		</svg>
	`;

	const svgContent6 = `
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 394.46 449.99">
			<defs>
				<style>
					.cls6-3{fill:#36a3c0;stroke:none;opacity:0.15;}
				</style>
			</defs>
			<g id="Layer_6" data-name="Layer 6">
				<g id="Layer_3-6" data-name="Layer 3">
					<rect class="cls6-3" x="-10" y="20" width="150" height="120" rx="6" transform="rotate(0 300 30)"/>
				</g>
			</g>
		</svg>
	`;

	const containerStyle = {
		backgroundImage: `url("data:image/svg+xml;utf8,${encodeURIComponent(svgContent)}")`,
		backgroundSize: 'cover', // Adjust background size as needed
		backgroundRepeat: 'no-repeat', // Prevent the background image from repeating
		// backgroundAttachment: 'fixed', // Keep the background fixed while scrolling
		// Add other CSS properties here to style your container
		// width: '100%',
		// height: '100vh', // You can adjust the height as needed
	};

	const containerStyle2 = {
		backgroundImage: `url("data:image/svg+xml;utf8,${encodeURIComponent(svgContent2)}")`,
		backgroundSize: 'cover', // Adjust background size as needed
		backgroundRepeat: 'no-repeat', // Prevent the background image from repeating
		// backgroundAttachment: 'fixed', // Keep the background fixed while scrolling
		// Add other CSS properties here to style your container
		// width: '100%',
		// height: '100vh', // You can adjust the height as needed
	};

	const containerStyle3 = {
		backgroundImage: `url("data:image/svg+xml;utf8,${encodeURIComponent(svgContent3)}")`,
		backgroundSize: 'cover', // Adjust background size as needed
		backgroundRepeat: 'no-repeat', // Prevent the background image from repeating
		// backgroundAttachment: 'fixed', // Keep the background fixed while scrolling
		// Add other CSS properties here to style your container
		// width: '100%',
		// height: '100vh', // You can adjust the height as needed
	};

	const containerStyle4 = {
		backgroundImage: `url("data:image/svg+xml;utf8,${encodeURIComponent(svgContent4)}")`,
		backgroundSize: 'cover', // Adjust background size as needed
		backgroundRepeat: 'no-repeat', // Prevent the background image from repeating
		// backgroundAttachment: 'fixed', // Keep the background fixed while scrolling
		// Add other CSS properties here to style your container
		// width: '100%',
		// height: '100vh', // You can adjust the height as needed
	};

	const containerStyle5 = {
		backgroundImage: `url("data:image/svg+xml;utf8,${encodeURIComponent(svgContent5)}")`,
		backgroundSize: 'cover', // Adjust background size as needed
		backgroundRepeat: 'no-repeat', // Prevent the background image from repeating
		// backgroundAttachment: 'fixed', // Keep the background fixed while scrolling
		// Add other CSS properties here to style your container
		// width: '100%',
		// height: '100vh', // You can adjust the height as needed
	};

	const containerStyle6 = {
		backgroundImage: `url("data:image/svg+xml;utf8,${encodeURIComponent(svgContent6)}")`,
		backgroundSize: 'cover', // Adjust background size as needed
		backgroundRepeat: 'no-repeat', // Prevent the background image from repeating
		// backgroundAttachment: 'fixed', // Keep the background fixed while scrolling
		// Add other CSS properties here to style your container
		// width: '100%',
		// height: '100vh', // You can adjust the height as needed
	};

	const wavesStyle = {
		position: 'absolute',
		top: '0',
		left: '0',
		width: '100%',
		overflow: 'hidden',
		lineHeight: '0'
	}

	return (
		<Fragment>
			<Header forceLightMenu={true} />

			<main id="content">
				<div style={{height: "92px"}} />

				{/* =========={ HERO }==========  */}
				<div id="hero" className="section pt-4 pt-md-4 pb-4 pb-md-4">
					{/* background overlay */}
					<div className="overlay bg-primary opacity-100 z-index-n1" />
					<div className="container">
						<div className="row align-items-center justify-content-center">
							{/* content */}
							<div className="col-11 col-lg-7" data-aos="fade-up">
								<div className="mt-6 mt-lg-4 py-0 py-lg-5 text-center">
									<h1 className="display-5 fw-bold text-white text-shadow text-uppercase mb-3">Bots At Your Service</h1>
									<p className="text-light mb-5 lead">At BotScheduling, we are dedicated to revolutionizing appointment management for businesses. Our intuitive online scheduling software <strong>empowers organizations</strong> of all sizes with <strong>automation</strong>, enhancing efficiency and customer experiences. <strong>We prioritize streamlining scheduling, optimizing resource use, and enabling growth, so businesses can focus on delivering exceptional services.</strong></p>
									<a className="btn btn-pill btn-warning mb-3 mb-sm-0 me-sm-4 text-uppercase" href="https://app.botscheduling.com/create">
										Get Started
									</a>
								</div>
							</div>{/* end content */}
						</div>
					</div>
				</div>{/* end hero */}

				<div className="bg-white">
					<svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none"
						 fill="#239bb7"
						 width="150%"
						 height="50px"
						 transform="rotateY(180deg)"
					>
						<path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill" />
					</svg>
				</div>

				{/* =========={ About }==========  */}
				<div id="about" className="section py-6 py-md-7 bg-white">
					<div className="container">
						{/* row */}
						<div className="row justify-content-between">
							<div className="col-4 col-xl-6" data-aos="fade-right">
								{/* About 2 Thumb */}
								<div className="text-center">
									<div className="mt-lg-5 mt-xl-0">
										<img className="img-fluid" src="src/logo/CustomizeWorkspace.png" alt="company profile" />
									</div>
								</div>
							</div>
							<div className="col-8 col-xl-6" data-aos="flip-up">
								<div className="py-0">
									<h2 className="fw-bold mb-3 mt-xl-5">Customize Workspace</h2>
									<p className="mb-5">BotScheduling's innovative workspace feature is designed to transform the way you manage appointments and schedules. With BotScheduling, your workspace becomes a dedicated hub where you effortlessly organize appointments, schedules, customer details, and essential information. This intuitive workspace offers a streamlined and structured view of your scheduling tasks, ensuring a clear focus on your activities. What's more, you can customize the workspace's interface and settings to align perfectly with your unique workflow, enhancing efficiency and productivity.</p>
									{/*<a href="#services" className="btn btn-warning">*/}
									{/*	Get Started*/}
									{/*	<svg className="bi bi-chevron-double-right ms-2" width="12px" height="12px" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">*/}
									{/*		<path fillRule="evenodd" d="M3.646 1.646a.5.5 0 01.708 0l6 6a.5.5 0 010 .708l-6 6a.5.5 0 01-.708-.708L9.293 8 3.646 2.354a.5.5 0 010-.708z" clipRule="evenodd" />*/}
									{/*		<path fillRule="evenodd" d="M7.646 1.646a.5.5 0 01.708 0l6 6a.5.5 0 010 .708l-6 6a.5.5 0 01-.708-.708L13.293 8 7.646 2.354a.5.5 0 010-.708z" clipRule="evenodd" />*/}
									{/*	</svg>*/}
									{/*</a>*/}
								</div>
							</div>
						</div>{/* end row */}
					</div>
				</div>{/* End About */}

				{/* =========={ About }==========  */}
				<div id="about" className="section py-6 py-md-7 bg-light" style={containerStyle2}>
					<div className="container">
						{/* row */}
						<div className="row justify-content-between">
							<div className="col-lg-6 col-xl-4" data-aos="flip-up">
								<div className="py-0">
									<h2 className="fw-bold mb-3">Invite Users</h2>
									<p className="mb-4">Inviting users to join your workspace facilitates collaborative scheduling efforts, enabling streamlined coordination and task delegation. Assigning roles ensures efficient task management, while a shared environment promotes transparency and communication. This feature enhances teamwork, minimizes manual efforts, and optimizes scheduling efficiency for your entire team.</p>
									<a href="#services" className="mb-4 btn btn-pill btn-secondary text-uppercase">
										More
									</a>
								</div>
							</div>
							<div className="col-lg-6 col-xl-7" data-aos="fade-right">
								{/* About 2 Thumb */}
								<div className="text-center">
									<div className="mt-lg-5">
										<img className="img-fluid" src="src/logo/InviteUsers.png" alt="company profile" />
									</div>
								</div>
							</div>
						</div>{/* end row */}
					</div>
				</div>{/* End About */}

				{/* =========={ About }==========  */}
				<div id="about" className="section py-6 py-md-7 bg-white">
					<div className="container">
						{/* row */}
						<div className="row justify-content-between">
							<div className="col-lg-6" data-aos="fade-right">
								{/* About 2 Thumb */}
								<div className="text-center">
									<div className="px-5 px-md-6 mb-5 mb-lg-0">
										<img className="img-fluid" src="src/logo/CustomizeWorkspace.png" alt="company profile" />
									</div>
								</div>
							</div>
							<div className="col-lg-6" data-aos="flip-up">
								<div className="py-0">
									<h2 className="fw-bold mb-3">Customer Management</h2>
									<p>BotScheduling's customer management helps to centralize the organization, tracking, and engagement with customers or clients. It provides a dedicated space where businesses can store, update, and access essential customer information, such as contact details, appointment history, preferences, and notes. This organized approach allows personalized interactions, efficient appointment scheduling, and improved customer service.</p>
									{/*<a href="#services" className="btn btn-warning">*/}
									{/*	Get Started*/}
									{/*	<svg className="bi bi-chevron-double-right ms-2" width="12px" height="12px" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">*/}
									{/*		<path fillRule="evenodd" d="M3.646 1.646a.5.5 0 01.708 0l6 6a.5.5 0 010 .708l-6 6a.5.5 0 01-.708-.708L9.293 8 3.646 2.354a.5.5 0 010-.708z" clipRule="evenodd" />*/}
									{/*		<path fillRule="evenodd" d="M7.646 1.646a.5.5 0 01.708 0l6 6a.5.5 0 010 .708l-6 6a.5.5 0 01-.708-.708L13.293 8 7.646 2.354a.5.5 0 010-.708z" clipRule="evenodd" />*/}
									{/*	</svg>*/}
									{/*</a>*/}
								</div>
							</div>
						</div>{/* end row */}
					</div>
				</div>{/* End About */}

				{/*<div className="bg-secondary">*/}
				{/*	<svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none"*/}
				{/*		 fill="#ffffff"*/}
				{/*		 width="120%"*/}
				{/*		 height="60px"*/}
				{/*		 transform="rotateY(180deg)"*/}
				{/*	>*/}
				{/*		<path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill" />*/}
				{/*	</svg>*/}
				{/*</div>*/}

				{/* =========={ About }==========  */}
				<div id="about" className="section py-6 py-md-7 bg-secondary" style={containerStyle4}>
					<div className="container">
						{/* row */}
						<div className="row justify-content-between">
							<div className="col-lg-6" data-aos="flip-up">
								<div className="py-0">
									<h2 className="fw-bold mb-3 text-white">Schedule Set-Up</h2>
									<p className="mb-4 text-white">Whether you're a small business owner, a team leader, or a service provider, setting up your schedule allows you to allocate time slots effectively, minimize conflicts, and enhance customer satisfaction. With customization options to match your unique workflow and the collaborative potential to share and assign tasks, this streamlined approach doesn't just manage schedules – it optimizes your operations for success.</p>
									<a href="#services" className="mb-4 btn btn-pill btn-outline-light text-uppercase">
										More
									</a>
								</div>
							</div>
							<div className="col-6" data-aos="fade-right">
								{/* About 2 Thumb */}
								<div className="text-center">
									<div className="">
										<img className="img-fluid" src="src/logo/ScheduleCreator.png" alt="company profile" />
									</div>
								</div>
							</div>
						</div>{/* end row */}
					</div>
				</div>{/* End About */}

				{/* =========={ About }==========  */}
				<div id="about" className="section py-6 py-md-7 bg-light" style={containerStyle5}>
					<div className="container">
						{/* row */}
						<div className="row justify-content-between">
							<div className="col-lg-6" data-aos="fade-right">
								{/* About 2 Thumb */}
								<div className="text-center">
									<div className="px-5 px-md-6 mb-5 mb-lg-0">
										<img className="img-fluid" src="src/logo/CustomizeWorkspace.png" alt="company profile" />
									</div>
								</div>
							</div>
							<div className="col-lg-6" data-aos="flip-up">
								<div className="py-0">
									<h2 className="fw-bold mb-3">Appointment Interface</h2>
									<p className="mb-5">BotScheduling's appointment interface allows users to create, manage, and organize appointments, meetings, or reservations. This feature includes options to set dates and time slots, allocate resources, assign staff or participants, and send notifications or reminders. Users can often customize appointment settings based on their preferences and business requirements. The appointment feature simplifies the process of coordinating events, optimizing resource usage, and ensuring smooth communication between users and customers.</p>
									{/*<a href="#services" className="btn btn-warning">*/}
									{/*	Get Started*/}
									{/*	<svg className="bi bi-chevron-double-right ms-2" width="12px" height="12px" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">*/}
									{/*		<path fillRule="evenodd" d="M3.646 1.646a.5.5 0 01.708 0l6 6a.5.5 0 010 .708l-6 6a.5.5 0 01-.708-.708L9.293 8 3.646 2.354a.5.5 0 010-.708z" clipRule="evenodd" />*/}
									{/*		<path fillRule="evenodd" d="M7.646 1.646a.5.5 0 01.708 0l6 6a.5.5 0 010 .708l-6 6a.5.5 0 01-.708-.708L13.293 8 7.646 2.354a.5.5 0 010-.708z" clipRule="evenodd" />*/}
									{/*	</svg>*/}
									{/*</a>*/}
								</div>
							</div>
						</div>{/* end row */}
					</div>
				</div>{/* End About */}

				{/* =========={ Resources }==========  */}
				<div id="about" className="section py-6 py-md-7 bg-secondary">
					<div className="container">
						<div className="row justify-content-between">
							{/* img block */}
							{/*<div className="col-lg-6 order-lg-2 text-white showcase-img" style={{backgroundImage: 'url("src/img-min/section/app-desktop2.jpg")'}} data-aos="fade-left" />*/}
							{/* content block */}
							<div className="col-lg-12" data-aos="flip-up">
								<div className="py-0">
									<h2 className="fw-bold text-center text-white mb-3">Utilizing Resources</h2>
									<p className="text-white text-center">When utilizing BotScheduling's resources, businesses can seamlessly manage their appointments by allocating specific resources tailored to the business needs. From conference rooms to dedicated employees or specialized equipment, the effective assignment of resources guarantees that each appointment is well-equipped, optimizing operations and enhancing the customer experience.</p>
									<div className="row mt-5 justify-content-between text-white">
										<div className="col-2 px-xl-6 px-lg-5 px-sm-4  p-0" data-aos="flip-up">
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="text-white">
												<path fill="#FFFFFF" d="M256 192l-39.5-39.5c4.9-12.6 7.5-26.2 7.5-40.5C224 50.1 173.9 0 112 0S0 50.1 0 112s50.1 112 112 112c14.3 0 27.9-2.7 40.5-7.5L192 256l-39.5 39.5c-12.6-4.9-26.2-7.5-40.5-7.5C50.1 288 0 338.1 0 400s50.1 112 112 112 112-50.1 112-112c0-14.3-2.7-27.9-7.5-40.5L499.2 76.8c7.1-7.1 7.1-18.5 0-25.6-28.3-28.3-74.1-28.3-102.4 0L256 192zm22.6 150.6l118.2 118.2c28.3 28.3 74.1 28.3 102.4 0 7.1-7.1 7.1-18.5 0-25.6L342.6 278.6l-64 64zM64 112a48 48 0 1196 0 48 48 0 11-96 0zm48 240a48 48 0 110 96 48 48 0 110-96z"></path>
											</svg>
										</div>
										<div className="col-2 px-xl-6 px-lg-5 px-sm-4  p-0" data-aos="flip-up">
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
												<path fill="#FFFFFF" d="M48 0C21.5 0 0 21.5 0 48v320c0 26.5 21.5 48 48 48h16c0 53 43 96 96 96s96-43 96-96h128c0 53 43 96 96 96s96-43 96-96h32c17.7 0 32-14.3 32-32s-14.3-32-32-32V237.3c0-17-6.7-33.3-18.7-45.3L512 114.7c-12-12-28.3-18.7-45.3-18.7H416V48c0-26.5-21.5-48-48-48H48zm368 160h50.7l77.3 77.3V256H416v-96zM112 416a48 48 0 1196 0 48 48 0 11-96 0zm368-48a48 48 0 110 96 48 48 0 110-96z"></path>
											</svg>
										</div>
										<div className="col-2 px-xl-6 px-lg-5 px-sm-4  p-0" data-aos="flip-up">
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
												<path fill="#FFFFFF" d="M465 7c-9.4-9.4-24.6-9.4-33.9 0L383 55c-2.4 2.4-4.3 5.3-5.5 8.5l-15.4 41-77.5 77.6c-45.1-29.4-99.3-30.2-131 1.6-11 11-18 24.6-21.4 39.6-3.7 16.6-19.1 30.7-36.1 31.6-25.6 1.3-49.3 10.7-67.3 28.6-44.8 44.9-36.4 125.9 18.7 181s136.1 63.5 180.9 18.7c17.9-17.9 27.4-41.7 28.6-67.3.9-17 15-32.3 31.6-36.1 15-3.4 28.6-10.5 39.6-21.4 31.8-31.8 31-85.9 1.6-131l77.6-77.6 41-15.4c3.2-1.2 6.1-3.1 8.5-5.5l48-48c9.4-9.4 9.4-24.6 0-33.9L465 7zM208 256a48 48 0 110 96 48 48 0 110-96z"></path>
											</svg>
										</div>
										<div className="col-2 px-xl-6 px-lg-5 px-sm-4  p-0" data-aos="flip-up">
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
												<path fill="#FFFFFF" d="M96 64c0-35.3 28.7-64 64-64h256c35.3 0 64 28.7 64 64v384h64c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32h64V64zm288 224a32 32 0 100-64 32 32 0 100 64z"></path>
											</svg>
										</div>
										<div className="col-2 px-xl-6 px-lg-5 px-sm-4  p-0" data-aos="flip-up">
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
												<path fill="#FFFFFF" d="M142.4 21.9c5.6 16.8-3.5 34.9-20.2 40.5L96 71.1V192c0 53 43 96 96 96s96-43 96-96V71.1l-26.1-8.7c-16.8-5.6-25.8-23.7-20.2-40.5s23.7-25.8 40.5-20.2l26.1 8.7c26.1 8.7 43.7 33.1 43.7 60.7V192c0 77.2-54.6 141.6-127.3 156.7C231 404.6 278.4 448 336 448c61.9 0 112-50.1 112-112v-70.7c-28.3-12.3-48-40.5-48-73.3 0-44.2 35.8-80 80-80s80 35.8 80 80c0 32.8-19.7 61-48 73.3V336c0 97.2-78.8 176-176 176-92.9 0-168.9-71.9-175.5-163.1C87.2 334.2 32 269.6 32 192V71.1c0-27.5 17.6-52 43.8-60.7l26.1-8.7c16.8-5.6 34.9 3.5 40.5 20.2zM480 224a32 32 0 100-64 32 32 0 100 64z"></path>
											</svg>
										</div>
										<div className="col-2 px-xl-6 px-lg-5 px-sm-4  p-0" data-aos="flip-up">
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
												<path fill="#FFFFFF" d="M312 32c-13.3 0-24 10.7-24 24s10.7 24 24 24h25.7l34.6 64H222.9l-27.4-38c-4.5-6.3-11.8-10-19.5-10h-56c-13.3 0-24 10.7-24 24s10.7 24 24 24h43.7l22.1 30.7-26.6 53.1c-10-2.5-20.5-3.8-31.2-3.8C57.3 224 0 281.3 0 352s57.3 128 128 128c65.3 0 119.1-48.9 127-112h49c8.5 0 16.3-4.5 20.7-11.8l84.8-143.5 21.7 40.1C402.4 276.3 384 312 384 352c0 70.7 57.3 128 128 128s128-57.3 128-128-57.3-128-128-128c-13.5 0-26.5 2.1-38.7 6L375.4 48.8C369.8 38.4 359 32 347.2 32H312zm146.6 271.7l32.3 59.7c6.3 11.7 20.9 16 32.5 9.7s16-20.9 9.7-32.5l-32.3-59.7c3.6-.6 7.4-.9 11.2-.9 39.8 0 72 32.2 72 72s-32.2 72-72 72-72-32.2-72-72c0-18.6 7-35.5 18.6-48.3zM133.2 368h65c-7.3 32.1-36 56-70.2 56-39.8 0-72-32.2-72-72s32.2-72 72-72c1.7 0 3.4.1 5.1.2l-24.2 48.5c-9 18.1 4.1 39.4 24.3 39.4zm33.7-48l50.7-101.3 72.9 101.2-.1.1H166.8zm90.6-128h108.4L317 274.8 257.4 192z"></path>
											</svg>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>{/* end about */}

				{/* =========={ About }==========  */}
				<div id="about" className="section py-6 py-md-7 bg-white">
					<div className="container">
						{/* row */}
						<div className="row justify-content-between">
							<div className="col-lg-12" data-aos="flip-up">
								<div className="py-0">
									<h2 className="fw-bold mb-3">Billing Integration</h2>
									<p className="mb-4">With BotScheduling's Stripe-integrated billing system, automated invoicing for appointments and services is generated, payments processed, and records updated without manual input. This streamlines finances, reduces admin tasks, and ensures accurate payment tracking. Ideal for businesses offering monetized services.</p>
									<a href="#services" className="btn btn-pill btn-secondary text-uppercase">
										More
									</a>
								</div>
							</div>
						</div>{/* end row */}
					</div>
				</div>{/* End About */}

				<div className="bg-secondary">
					<svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none"
						 fill="#ffffff"
						 width="200%"
						 height="50px"
						 transform="rotateY(180deg)"
					>
						<path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill" />
					</svg>
				</div>
			</main>

			<Footer />
		</Fragment>
	)
}

export default Product;