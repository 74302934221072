import React, { Fragment } from 'react';

const HealthMindAndBody = () => {
	return (
		<Fragment>
			{/* =========={ About }==========  */}
			<div id="about" className="section py-6 py-md-7 bg-white">
				<div className="container">
					{/* row */}
					<div className="row justify-content-between">
						<div className="col-lg-12" data-aos="flip-up">
							<div className="py-0">
								<h1 className="fw-bold mb-3">Health, Mind and Beauty</h1>
								<p>BotScheduling is transforming the landscape of health, mind, and beauty businesses, benefiting establishments like doctors' offices, counseling centers, salons, nail studios, chiropractic clinics, and massage parlors.</p>
							</div>
						</div>
					</div>
				</div>
			</div>{/* End About */}

			{/* =========={ About }==========  */}
			<div id="about" className="section py-6 py-md-7 bg-light">
				<div className="container">
					{/* row */}
					<div className="row justify-content-between">
						<div className="col-lg-12" data-aos="flip-up">
							<div className="py-0">
								<h2 className="fw-bold mb-3">Health</h2>
								<ul>
									<li>Streamline appointment management and reduce no-shows.</li>
									<li>Boost patient engagement and satisfaction.</li>
									<li>Simplify scheduling for enhanced clinic efficiency.</li>
									<li>Time and resource savings for healthcare providers.</li>
									<li>Ensuring excellent patient care with BotScheduling.</li>
								</ul>
							</div>
						</div>
					</div>
				</div>

				<div className="container mt-4">
					{/* row */}
					<div className="row justify-content-between">
						<div className="col-lg-12" data-aos="flip-up">
							<div className="py-0">
								<h2 className="fw-bold mb-3">Mind</h2>
								<ul>
									<li>Versatile solution designed specifically for mental health and counseling.</li>
									<li>Promote client involvement and engagement.</li>
									<li>Intuitive features for easy appointment booking.</li>
									<li>Efficient schedule management and resource allocation.</li>
									<li>Provide clients with the focused care they deserve with BotScheduling.</li>
								</ul>
							</div>
						</div>
					</div>
				</div>

				<div className="container mt-4">
					{/* row */}
					<div className="row justify-content-between">
						<div className="col-lg-12" data-aos="flip-up">
							<div className="py-0">
								<h2 className="fw-bold mb-3">Beauty</h2>
								<ul>
									<li>Tailored solution for beauty and wellness businesses.</li>
									<li>Enhances client engagement and overall satisfaction.</li>
									<li>User-friendly features streamline appointment scheduling.</li>
									<li>Optimizes salon operations, ensuring peak efficiency.</li>
									<li>Efficiently organizes appointments and resource allocation.</li>
									<li>Empowers beauty professionals to prioritize exceptional client services.</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>{/* End About */}
		</Fragment>
	)
}

export default HealthMindAndBody;