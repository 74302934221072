import React, { Fragment, useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import { ReactComponent as ReactLogoDark } from '../../../../logo/BotSchedulingDocsLogoOriginal.svg';
import { ReactComponent as ReactLogoLight } from '../../../../logo/BotSchedulingLogoWhite.svg';

const Header = ({ forceLightMenu }: any) => {
	//const [scrollPosition, setScrollPosition] = useState(window.pageYOffset);
	// const [navCss, setNavCss] = useState("navbar_dark");
	const navMenu = useRef<HTMLElement | null>(null);

	const toggleMenu = () => {
		let nav_dark = document.querySelector(".dark-to-light")

		console.log('innerWidth' + window.innerWidth);
	}

	function handleWindowResize() {
		navbarScroller(window.scrollY);
	}

	const navbarScroller = (scrollPosition: any) => {
			//scrollPosition = window.scrollY;

			if (forceLightMenu === true) {
				if (navMenu.current !== null) {
					scrollPosition = 150;//navMenu.current.offsetHeight;
				}
			}

			let nav_dark = document.querySelector(".dark-to-light");
			// var nav_light = document.querySelector(".light-to-dark");
			// var nav_one = document.querySelector(".main-nav");

			const width = window.innerWidth;
			if (width < 992) {
				if ( nav_dark != null) {
					nav_dark.classList.add("navbar-scrolled", "navbar-light");
					nav_dark.classList.remove("navbar-dark");
				}
				return;
			}

			if ( nav_dark != null) {
				if (scrollPosition >= 150) {
					nav_dark.classList.add("navbar-scrolled", "navbar-light");
					nav_dark.classList.remove("navbar-dark");
					//nav_dark.addClass("navbar-scrolled navbar-light");
					// setNavCss("navbar-scrolled navbar-light");
				} else {
					nav_dark.classList.add("navbar-dark");
					nav_dark.classList.remove("navbar-scrolled", "navbar-light");
					// setNavCss("navbar-dark");
				}
			}
			//}
		//}
	}

	useEffect(() => {
		handleWindowResize();
		window.addEventListener("resize", handleWindowResize);


		navbarScroller(window.scrollY);
	//
	// 	// window.addEventListener('load', function () {
	// 	// 	navbarScroller();
	// 	// });
	//
		window.addEventListener('scroll', function () {
			navbarScroller(window.scrollY);
		});
	// 		// scrollPosition = window.pageYOffset;
	// 		//
	// 		// console.log(scrollPosition + '|' + nav_dark.offsetHeight);
	// 		//
	// 		// if (scrollPosition >= nav_dark.offsetHeight) {
	// 		// 	setNavbar("navbar-scrolled navbar-light");
	// 		// } else {
	// 		// 	setNavbar("navbar-dark");
	// 		// }
	//
	// 		// if ( nav_dark !=null) {
	// 		// 	var nav_dark_height = nav_dark.offsetHeight;
	// 		// } else {
	// 		// 	var nav_dark_height = header_offset;
	// 		// }
	// 		//
	// 		// scrollpos = document.body.scrollTop || document.documentElement.scrollTop;
	// 		// console.log(scrollpos);
	// 		// if (scrollpos >= nav_dark_height) {
	// 		// 	add_class_on_scroll();
	// 		// } else {
	// 		// 	remove_class_on_scroll();
	// 		// }
	//
	// 		//const css2 = "navbar-scrolled navbar-light";
	//
	// 		// if (nav_dark != null) {
	// 		// 	// if nav start not in top and not scroll
	// 		// 	window.addEventListener('load', function () {
	// 		// 		rnavCustom();
	// 		// 	});
	// 		//
	// 		// 	// if nav scroll
	// 		// 	window.addEventListener('scroll', function () {
	// 		// 		rnavCustom();
	// 		// 	});
	// 		// } else if (nav_light != null) {
	// 		// 	// if nav start not in top and not scroll
	// 		// 	window.addEventListener('load', function () {
	// 		// 		rnavCustom2();
	// 		// 	});
	// 		//
	// 		// 	// if nav scroll
	// 		// 	window.addEventListener('scroll', function () {
	// 		// 		rnavCustom2();
	// 		// 	});
	// 		// } else if (nav_one != null) {
	// 		// 	// if nav start not in top and not scroll
	// 		// 	window.addEventListener('load', function () {
	// 		// 		rnavCustom3();
	// 		// 	});
	// 		//
	// 		// 	// if nav scroll
	// 		// 	window.addEventListener('scrool', function () {
	// 		// 		rnavCustom3();
	// 		// 	});
	// 		// }
	// 	//});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);//navbarScroller]);

	// Navbar if scroll down
	//const myNavigation = function () {
	// 		var scrollpos = document.body.scrollTop || document.documentElement.scrollTop;
	// 		var nav_dark = document.querySelector(".dark-to-light");
	// 		var nav_light = document.querySelector(".light-to-dark");
	// 		var nav_one = document.querySelector(".main-nav");
	//
	// 		// navbar on scroll
	// 		var add_class_on_scroll = function add_class_on_scroll() {
	// 			return nav_dark.classList.add("navbar-scrolled","navbar-light"),
	// 				nav_dark.classList.remove("navbar-dark");
	// 		};
	// 		var remove_class_on_scroll = function remove_class_on_scroll() {
	// 			console.log('remove' + '|' + nav_dark.classList);
	// 			return nav_dark.classList.remove("navbar-scrolled","navbar-light"),
	// 				nav_dark.classList.add("navbar-dark");
	// 		};
	// 		var add_class_on_scroll2 = function add_class_on_scroll2() {
	// 			return nav_light.classList.add("navbar-scrolled","navbar-dark"),
	// 				nav_light.classList.remove("navbar-light");
	// 		};
	// 		var remove_class_on_scroll2 = function remove_class_on_scroll2() {
	// 			return nav_light.classList.remove("navbar-scrolled","navbar-dark"),
	// 				nav_light.classList.add("navbar-light");
	// 		};
	// 		var add_class_on_scroll3 = function add_class_on_scroll3() {
	// 			return nav_one.classList.add("navbar-scrolled");
	// 		};
	// 		var remove_class_on_scroll3 = function remove_class_on_scroll3() {
	// 			return nav_one.classList.remove("navbar-scrolled");
	// 		};
	//
	// 		var navCustom = function navCustom() {
	// 			if ( nav_dark !=null) {
	// 				var nav_dark_height = nav_dark.offsetHeight;
	// 			} else {
	// 				var nav_dark_height = header_offset;
	// 			}
	//
	// 			scrollpos = document.body.scrollTop || document.documentElement.scrollTop;
	// 			console.log(scrollpos);
	// 			if (scrollpos >= nav_dark_height) {
	// 				add_class_on_scroll();
	// 			} else {
	// 				remove_class_on_scroll();
	// 			}
	// 		}
	//
	// 		var navCustom2 = function navCustom2() {
	// 			if ( nav_light !=null) {
	// 				var nav_light_height = nav_light.offsetHeight;
	// 			} else {
	// 				var nav_light_height = header_offset;
	// 			}
	// 			scrollpos = document.body.scrollTop || document.documentElement.scrollTop;
	// 			console.log(scrollpos);
	// 			if (scrollpos >= nav_light_height) {
	// 				add_class_on_scroll2();
	// 			} else {
	// 				remove_class_on_scroll2();
	// 			}
	// 		}
	//
	// 		var navCustom3 = function navCustom3() {
	// 			if ( nav_one !=null) {
	// 				var nav_one_height = nav_one.offsetHeight;
	// 			} else {
	// 				var nav_one_height = header_offset;
	// 			}
	// 			scrollpos = document.body.scrollTop || document.documentElement.scrollTop;
	// 			console.log(scrollpos);
	// 			if (scrollpos >= nav_one_height) {
	// 				add_class_on_scroll3();
	// 			} else {
	// 				remove_class_on_scroll3();
	// 			}
	// 		}
	//
	// 		if (nav_dark != null) {
	// 			// if nav start not in top and not scroll
	// 			window.addEventListener('load', function () {
	// 				navCustom();
	// 			});
	//
	// 			// if nav scroll
	// 			window.addEventListener('scroll', function () {
	// 				navCustom();
	// 			});
	// 		} else if (nav_light != null) {
	// 			// if nav start not in top and not scroll
	// 			window.addEventListener('load', function () {
	// 				navCustom2();
	// 			});
	//
	// 			// if nav scroll
	// 			window.addEventListener('scroll', function () {
	// 				navCustom2();
	// 			});
	// 		} else if (nav_one != null) {
	// 			// if nav start not in top and not scroll
	// 			window.addEventListener('load', function () {
	// 				navCustom3();
	// 			});
	//
	// 			// if nav scroll
	// 			window.addEventListener('scrool', function () {
	// 				navCustom3();
	// 			});
	// 		}
		//}

	/**
	 * ------------------------------------------------------------------------
	 * Launch Functions
	 * ------------------------------------------------------------------------
	 */

	//myNavigation();

	return (
		<Fragment>
			<header className="header">
				{/* Navigation */}
				<nav className="navbar fixed-top navbar-expand-lg navbar-light bg-light border-bottom z-index-50 py-2 py-lg-0 px-4">
					<div className="container">
						{/* logo */}
						<div className="nav-logo6">
							<Link className="navbar-brand main-logo" to="/docs">
								{/* <span class="h3 text-white fw-bold mt-2">Onekit</span> */}
								{/*<img src="../src/img-min/logo/logo.png" alt="LOGO" />*/}
								<ReactLogoDark width="100px" height="48px" />
							</Link>
						</div>
						{/* menu  */}
						<button className="navbar-toggler" type="button"
								data-bs-toggle="collapse"
								data-bs-target="#navbarTogglerDemo"
								aria-controls="navbarTogglerDemo"
								aria-expanded="false"
								aria-label="Toggle navigation">
							<span className="navbar-toggler-icon"></span>
						</button>
						<div className="collapse navbar-collapse" id="navbarTogglerDemo">
							<ul className="navbar-nav ms-auto mt-2 mt-lg-0">
								<li className="nav-item bold">
									<Link className="nav-link" to="/">
										<svg className="p-0 mb-1" xmlns="http://www.w3.org/2000/svg" width="12px" height="12px" fill="currentColor" viewBox="0 0 512 512"><polyline points="328 112 184 256 328 400" style={{fill: 'none', stroke: 'currentColor', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '48px'}} /></svg>
										MAIN SITE
									</Link>
								</li>
								<li className="nav-item">
									<Link className="nav-link" to="/docs">DOCS</Link>
								</li>
								<li className="nav-item">
									<Link className="nav-link" to="/docs/api">DEVELOPER</Link>
								</li>
								<li className="nav-item">
									<a className="nav-link" target="_blank" rel="noreferrer" href="https://app.botscheduling.com/auth/login">SIGN IN</a>
								</li>
							</ul>
							<div className="d-grid d-lg-block my-3 my-lg-0 ms-0 ms-lg-4">
								<a className="btn btn-secondary btn-sm" target="_blank" rel="noreferrer" href="https://app.botscheduling.com/create">
									GET STARTED
								</a>
							</div>
						</div>
					</div>
				</nav>
				{/* end navigation */}
			</header>
		</Fragment>
	)
}

export default Header;