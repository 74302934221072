import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Home from './components/pages/Home';
import Product from "./components/pages/Product";
import Pricing from "./components/pages/Pricing";
import Terms from "./components/pages/Terms";
import Privacy from "./components/pages/Privacy";
import NotFound from "./components/pages/NotFound";
import Docs from "./components/pages/docs/Docs";
import Developer from "./components/pages/docs/Developer";

import './App.css';

import { library, IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { fas, IconDefinition as SolidIconDefinition } from '@fortawesome/free-solid-svg-icons';
import * as Brands from '@fortawesome/free-brands-svg-icons';
import PrivateRoute from "./components/routing/PrivateRoute";
import Auth from "./components/pages/Auth";
import Features from "./components/pages/Features";
import ScrollToTop from './components/routing/ScrollToTop';
import Industries from "./components/pages/Industries";
import Support from "./components/pages/docs/Support";
import Contact from "./components/pages/Contact";

// Define the types of the arrays
const iconList: Array<SolidIconDefinition> = Object.keys(fas)
    .filter((key) => key !== 'fas' && key !== 'prefix')
    .map((icon) => fas[icon] as SolidIconDefinition);

const brandIconList: Array<IconDefinition> = Object.keys(Brands)
    .filter((key) => key !== 'fab' && key !== 'prefix')
    .map((icon) => (Brands as unknown as { [key: string]: IconDefinition })[icon]);

library.add(...iconList, ...brandIconList);

const App = () => {

  return (
    <Router>
        <ScrollToTop />
        <Routes>
            <Route path='/' element={<PrivateRoute />}>
                <Route path='/' element={<Home />}/>
            </Route>
            <Route path='/features' element={<PrivateRoute />}>
                <Route path='/features' element={<Features />}/>
            </Route>
            <Route path='/product' element={<PrivateRoute />}>
                <Route path='/product' element={<Product />}/>
            </Route>
            <Route path='/pricing' element={<PrivateRoute />}>
                <Route path='/pricing' element={<Pricing />}/>
            </Route>
            <Route path='/terms'>
                <Route path='/terms' element={<Terms />}/>
            </Route>
            <Route path='/privacy'>
                <Route path='/privacy' element={<Privacy />}/>
            </Route>
            <Route path='/docs/api' element={<PrivateRoute />}>
                <Route path='/docs/api' element={<Developer />}/>
            </Route>
            <Route path='/docs/support' element={<PrivateRoute />}>
                <Route path='/docs/support' element={<Support />}/>
            </Route>
            <Route path='/docs/:title' element={<PrivateRoute />}>
                <Route path='/docs/:title' element={<Docs />}/>
            </Route>
            <Route path='/docs' element={<PrivateRoute />}>
                <Route path='/docs' element={<Docs />}/>
            </Route>
            <Route path='/industries/:title' element={<PrivateRoute />}>
                <Route path='/industries/:title' element={<Industries />}/>
            </Route>
            <Route path='/contact' element={<PrivateRoute />}>
                <Route path='/contact' element={<Contact />}/>
            </Route>
            {/*<Route path='/secret-route' element={<PrivateRoute/>}>*/}
            {/*    <Route path='/secret-route' element={<Privacy />}/>*/}
            {/*</Route>*/}
            <Route path='/auth' element={ <Auth /> } />
            <Route path='*' element={ <NotFound /> } />
        </Routes>
    </Router>
  );
}

export default App;