import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL

const instance = axios.create({
	baseURL: apiUrl,
});

instance.interceptors.response.use(function (response) {
	return response;
}, function (error) {
	return Promise.reject(error);
});

export default instance;