import React, { Fragment } from 'react';

const ProfessionalServices = () => {
	return (
		<Fragment>
			<div id="about" className="section py-6 py-md-7 bg-white">
				<div className="container">
					{/* row */}
					<div className="row justify-content-between">
						<div className="col-lg-12" data-aos="flip-up">
							<div className="py-0">
								<h1 className="fw-bold mb-3">Professional Services</h1>
								<p>Professional service businesses such as accounting firms, real estate agencies, consulting companies, financial services providers, legal practices and more are benefiting from the intelligent algorithms and automation that BotScheduling offers.</p>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div id="about" className="section py-6 py-md-7 bg-light">
				<div className="container">
					{/* row */}
					<div className="row justify-content-between">
						<div className="col-lg-12" data-aos="flip-up">
							<div className="py-0">
								<h2 className="fw-bold mb-3">Accounting Firms</h2>
								<ul>
									<li>Simplify appointment management, saving time and reducing hassles.</li>
									<li>Utilize user-friendly features for efficient appointment booking.</li>
									<li>Optimize office workflows for improved efficiency.</li>
									<li>Efficiently organize appointments and allocate resources.</li>
									<li>Prioritize delivering top-tier financial services to clients.</li>
								</ul>
							</div>
						</div>
					</div>
				</div>

				<div className="container mt-4">
					{/* row */}
					<div className="row justify-content-between">
						<div className="col-lg-12" data-aos="flip-up">
							<div className="py-0">
								<h2 className="fw-bold mb-3">Real Estate</h2>
								<ul>
									<li>Simplify appointment scheduling in a busy real estate environment.</li>
									<li>User-friendly online appointment scheduling software.</li>
									<li>Eliminate scheduling conflicts and reduce missed meetings.</li>
									<li>Free up time for real estate professionals to focus on clients and business growth.</li>
									<li>Streamline schedules and enhance client relationships with ease.</li>
								</ul>
							</div>
						</div>
					</div>
				</div>

				<div className="container mt-4">
					{/* row */}
					<div className="row justify-content-between">
						<div className="col-lg-12" data-aos="flip-up">
							<div className="py-0">
								<h2 className="fw-bold mb-3">Legal Practices</h2>
								<ul>
									<li>Streamline appointment and client meeting management.</li>
									<li>Simplify appointment booking, reducing complexity.</li>
									<li>Minimize scheduling conflicts and enhance client engagement.</li>
									<li>Optimize schedules and resource allocation.</li>
									<li>Enhance efficiency and client satisfaction in the fast-paced legal industry.</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	)
}

export default ProfessionalServices;