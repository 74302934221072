import React, { Fragment, useEffect, useRef } from 'react';
import Header from './fragments/Header';
import Footer from './fragments/Footer';
import Typed from "typed.js";
import { Link } from "react-router-dom";

export const Home = () => {
	// Create Ref element.
	const el = useRef(null);

	useEffect(() => {
		if (el.current !== null) {
			const typed = new Typed(el.current, {
				strings: ["Solution", "Tool", "Assistant", "Bot"], // Strings to display
				// Speed settings, try different values until you get good results
				startDelay: 300,
				typeSpeed: 100,
				backSpeed: 100,
				backDelay: 100,
				loop: true
			});

			return () => {
				typed.destroy();
			};
		}
	}, []);

	return (
		<Fragment>
			<Header forceLightMenu={false} />

			<main id="content">
				<div id="hero" className="section bg-gradient-primary2 py-8 overflow-hidden">
					<div className="overlay bg-gradient-primary opacity-70 z-index-n1"></div>

					<div className="particle">
						<div className="particle-move-up d-none d-lg-block particle-move-up-1 text-light z-index-n1 opacity-60">
							<svg xmlns="http://www.w3.org/2000/svg" width="32px" height="32px" fill="currentColor"
								 viewBox="0 0 512 512">
								<rect x="32" y="64" width="448" height="320" rx="32" ry="32"
									  style={{fill:"none",stroke:"currentColor",strokeLinejoin:"round",strokeWidth:"32px"}} />
								<polygon points="304 448 296 384 216 384 208 448 304 448"
										 style={{stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}} />
								<line x1="368" y1="448" x2="144" y2="448"
									  style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}} />
								<path
									d="M32,304v48a32.09,32.09,0,0,0,32,32H448a32.09,32.09,0,0,0,32-32V304Zm224,64a16,16,0,1,1,16-16A16,16,0,0,1,256,368Z"/>
							</svg>
						</div>
						<div className="particle-move-up particle-move-up-2 text-light z-index-n1 opacity-60">
							<svg xmlns="http://www.w3.org/2000/svg" width="32px" height="32px" fill="currentColor"
								 viewBox="0 0 512 512">
								<rect x="48" y="80" width="416" height="384" rx="48" ry="48"
									  style={{fill:"none",stroke:"currentColor",strokeLinejoin:"round",strokeWidth:"32px"}} />
								<path
									d="M397.82,80H114.18C77.69,80,48,110.15,48,147.2V208H64c0-16,16-32,32-32H416c16,0,32,16,32,32h16V147.2C464,110.15,434.31,80,397.82,80Z"/>
								<circle cx="296" cy="232" r="24"/>
								<circle cx="376" cy="232" r="24"/>
								<circle cx="296" cy="312" r="24"/>
								<circle cx="376" cy="312" r="24"/>
								<circle cx="136" cy="312" r="24"/>
								<circle cx="216" cy="312" r="24"/>
								<circle cx="136" cy="392" r="24"/>
								<circle cx="216" cy="392" r="24"/>
								<circle cx="296" cy="392" r="24"/>
								<line x1="128" y1="48" x2="128" y2="80"
									  style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}} />
								<line x1="384" y1="48" x2="384" y2="80"
									  style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}} />
							</svg>
						</div>
						<div
							className="particle-move-up d-none d-sm-block particle-move-up-3 text-light z-index-n1 opacity-60">
							<svg xmlns="http://www.w3.org/2000/svg" width="32px" height="32px" fill="currentColor"
								 viewBox="0 0 512 512">
								<path
									d="M400,240c-8.89-89.54-71-144-144-144-69,0-113.44,48.2-128,96C68,198,16,235.59,16,304c0,66,54,112,120,112H396c55,0,100-27.44,100-88C496,268.18,443,242.24,400,240Z"
									style={{fill:"none",stroke:"currentColor",strokeLinejoin:"round",strokeWidth:"32px"}} />
							</svg>
						</div>
						<div
							className="particle-move-up d-none d-xl-block particle-move-up-4 text-light z-index-n1 opacity-60">
							<svg xmlns="http://www.w3.org/2000/svg" width="32px" height="32px" fill="currentColor"
								 viewBox="0 0 512 512">
								<path
									d="M344,144c-3.92,52.87-44,96-88,96s-84.15-43.12-88-96c-4-55,35-96,88-96S348,90,344,144Z"
									style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}} />
								<path
									d="M256,304c-87,0-175.3,48-191.64,138.6C62.39,453.52,68.57,464,80,464H432c11.44,0,17.62-10.48,15.65-21.4C431.3,352,343,304,256,304Z"
									style={{fill:"none",stroke:"currentColor",strokeMiterlimit:"10",strokeWidth:"32px"}} />
							</svg>
						</div>
						<div
							className="particle-move-up d-none d-sm-block particle-move-up-5 text-light z-index-n1 opacity-60">
							<svg xmlns="http://www.w3.org/2000/svg" width="32px" height="32px" fill="currentColor"
								 viewBox="0 0 512 512">
								<rect x="128" y="16" width="256" height="480" rx="48" ry="48"
									  style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}} />
								<path
									d="M176,16h24a8,8,0,0,1,8,8h0a16,16,0,0,0,16,16h64a16,16,0,0,0,16-16h0a8,8,0,0,1,8-8h24"
									style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}} />
							</svg>
						</div>
						<div
							className="particle-move-up border-success text-light particle-move-up-6 z-index-n1 opacity-60">
							<svg xmlns="http://www.w3.org/2000/svg" width="32px" height="32px" fill="currentColor"
								 viewBox="0 0 512 512">
								<path
									d="M434.67,285.59v-29.8C434.67,157.06,354.43,77,255.47,77a179,179,0,0,0-140.14,67.36m-38.53,82v29.8C76.8,355,157,435,256,435a180.45,180.45,0,0,0,140-66.92"
									style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}} />
								<polyline points="32 256 76 212 122 256"
										  style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}} />
								<polyline points="480 256 436 300 390 256"
										  style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}} />
							</svg>
						</div>
						<div className="particle-move-up particle-move-up-7 z-index-n1 text-light opacity-60">
							<svg xmlns="http://www.w3.org/2000/svg" width="32px" height="32px" fill="currentColor"
								 viewBox="0 0 512 512">
								<rect x="48" y="80" width="416" height="384" rx="48" ry="48"
									  style={{fill:"none",stroke:"currentColor",strokeLinejoin:"round",strokeWidth:"32px"}} />
								<path
									d="M397.82,80H114.18C77.69,80,48,110.15,48,147.2V208H64c0-16,16-32,32-32H416c16,0,32,16,32,32h16V147.2C464,110.15,434.31,80,397.82,80Z"/>
								<circle cx="296" cy="232" r="24"/>
								<circle cx="376" cy="232" r="24"/>
								<circle cx="296" cy="312" r="24"/>
								<circle cx="376" cy="312" r="24"/>
								<circle cx="136" cy="312" r="24"/>
								<circle cx="216" cy="312" r="24"/>
								<circle cx="136" cy="392" r="24"/>
								<circle cx="216" cy="392" r="24"/>
								<circle cx="296" cy="392" r="24"/>
								<line x1="128" y1="48" x2="128" y2="80"
									  style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}} />
								<line x1="384" y1="48" x2="384" y2="80"
									  style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}} />
							</svg>
						</div>
						<div className="particle-move-up particle-move-up-8 z-index-n1 text-light opacity-60">
							<svg xmlns="http://www.w3.org/2000/svg" width="32px" height="32px" fill="currentColor"
								 viewBox="0 0 512 512">
								<rect x="48" y="80" width="416" height="384" rx="48" ry="48"
									  style={{fill:"none",stroke:"currentColor",strokeLinejoin:"round",strokeWidth:"32px"}} />
								<path
									d="M397.82,80H114.18C77.69,80,48,110.15,48,147.2V192h8c0-16,24-32,40-32H416c16,0,40,16,40,32h8V147.2C464,110.15,434.31,80,397.82,80Z"/>
								<line x1="128" y1="48" x2="128" y2="80"
									  style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}} />
								<line x1="384" y1="48" x2="384" y2="80"
									  style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}} />
								<rect x="96" y="208" width="128" height="128" rx="28.57" ry="28.57"/>
								<path d="M464,256V208a48.14,48.14,0,0,0-48-48H96a48.14,48.14,0,0,0-48,48v48"
									  style={{fill:"none",stroke:"currentColor",strokeLinejoin:"round",strokeWidth:"32px"}} />
							</svg>
						</div>
						<div className="particle-move-up particle-move-up-9 z-index-n1 text-light opacity-60">
							<svg xmlns="http://www.w3.org/2000/svg" width="32px" height="32px" fill="currentColor"
								 viewBox="0 0 512 512">
								<rect x="48" y="80" width="416" height="384" rx="48" ry="48"
									  style={{fill:"none",stroke:"currentColor",strokeLinejoin:"round",strokeWidth:"32px"}} />
								<path
									d="M397.82,80H114.18C77.69,80,48,110.15,48,147.2V192h8c0-16,24-32,40-32H416c16,0,40,16,40,32h8V147.2C464,110.15,434.31,80,397.82,80Z"/>
								<line x1="128" y1="48" x2="128" y2="80"
									  style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}} />
								<line x1="384" y1="48" x2="384" y2="80"
									  style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}} />
								<rect x="96" y="208" width="128" height="128" rx="28.57" ry="28.57"/>
								<path d="M464,256V208a48.14,48.14,0,0,0-48-48H96a48.14,48.14,0,0,0-48,48v48"
									  style={{fill:"none",stroke:"currentColor",strokeLinejoin:"round",strokeWidth:"32px"}} />
							</svg>
						</div>
					</div>

					<div className="container">
						<div className="row justify-content-center">
							<div className="col-md-9 col-lg-6 align-self-center pe-lg-5" data-aos="flip-up">
								<div className="text-center text-lg-start mt-4 mt-lg-0">
									<div className="mb-3">
										<span className="badge bg-secondary rounded">New</span>
										<span className="text-light ms-1">FREE plan available!</span>
									</div>
									<div className="mb-5">
										<h1 className="display-4 fw-bold text-white mb-3">An <span
											className="text-warning">Intelligent</span><br />Online Scheduling<br /><span ref={el}></span>
										</h1>
										<p className="lead text-light">Streamline your business with scheduling
											automation and allow your customers to book anytime, anywhere.</p>
									</div>
									<Link className="btn btn-warning btn-pill hover-button-up" target="_blank" rel="noreferrer" to="https://app.botscheduling.com/create">
										GET STARTED
									</Link>
								</div>
							</div>
							<div className="col-md-9 col-lg-6 align-self-center">
								<div className="px-3 px-sm-7 px-md-2 px-xl-4 mt-5 mt-lg-0 mb-2" data-aos="fade-up"
									 data-aos-delay="100">
									<img className="img-fluid animated-pulse" src="/src/logo/Robot-Scheduling.png" alt="images title" />
								</div>
							</div>
						</div>
					</div>

					<figure className="waves-bottom-center text-light mb-lg-n4 z-index-n1">
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
							<path className="opacity-10 translate-top-2" fill="currentColor" fillOpacity="1"
								  d="M0,160L60,186.7C120,213,240,267,360,245.3C480,224,600,128,720,106.7C840,85,960,139,1080,149.3C1200,160,1320,128,1380,112L1440,96L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"></path>
							<path className="opacity-40 translate-top-1" fill="currentColor" fillOpacity="1"
								  d="M0,160L60,186.7C120,213,240,267,360,245.3C480,224,600,128,720,106.7C840,85,960,139,1080,149.3C1200,160,1320,128,1380,112L1440,96L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"></path>
							<path fill="currentColor" fillOpacity="1"
								  d="M0,160L60,186.7C120,213,240,267,360,245.3C480,224,600,128,720,106.7C840,85,960,139,1080,149.3C1200,160,1320,128,1380,112L1440,96L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"></path>
						</svg>
					</figure>
				</div>

				<div id="features" className="section py-6 py-md-7 bg-light">
					<div className="container">
						<div className="row text-center">
							<div className="col-12">
								<div className="mb-5">
									<h2 className="h2 fw-bold">Features Of The Future</h2>
									{/*<hr class="divider my-4 mx-auto bg-warning border-warning">*/}
									<p className="lead text-muted">Our features enhance scheduling and boost productivity.</p>
								</div>
							</div>
						</div>

						<div className="row">
							<div className="d-none d-lg-block d-xl-block col-lg-4 text-center order-lg-2" data-aos="fade-up">
								<div className="px-7 1px-md-9 1px-lg-6 1px-xl-7 mb-5 mb-lg-0 mt-5">
									<img src="/src/logo/RocketBot.png" className="img-fluid animated-up-down" alt="landing page" />
								</div>
								<div className="px-0 1px-md-9 1px-lg-5 1px-xl-6 mb-5 mb-lg-0">
									<img src="/src/logo/RocketCloud.png" className="img-fluid" alt="landing page" />
								</div>
							</div>
							<div className="col-6 col-lg-4 order-lg-1" data-aos="fade-right" data-aos-delay="100">
								<div className="three-features1 pb-4 position-relative features-end text-start text-lg-end">
									{/*<svg xmlns="http://www.w3.org/2000/svg" className="features-icon text-danger"*/}
									{/*	 width="3.5rem" height="3.5rem" fill="currentColor" viewBox="0 0 512 512">*/}
									{/*	<rect x="48" y="48" width="176" height="176" rx="20" ry="20"*/}
									{/*		  style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}} />*/}
									{/*	<rect x="288" y="48" width="176" height="176" rx="20" ry="20"*/}
									{/*		  style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}} />*/}
									{/*	<rect x="48" y="288" width="176" height="176" rx="20" ry="20"*/}
									{/*		  style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}} />*/}
									{/*	<rect x="288" y="288" width="176" height="176" rx="20" ry="20"*/}
									{/*		  style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}} />*/}
									{/*</svg>*/}

									{/*<h3 className="h5">Scalable</h3>*/}
									{/*<p>Effortlessly expand your business with our scalable solution that adapts to your evolving needs.</p>*/}
									<div className="text-center">
										<div className="display-4 text-primary mb-3">
											{/* <i class="far fa-life-ring"></i> */}
											<svg xmlns="http://www.w3.org/2000/svg" className="text-danger"
												 width="48px" height="48px" fill="currentColor" viewBox="0 0 512 512">
												<rect x="48" y="48" width="176" height="176" rx="20" ry="20"
													  style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}} />
												<rect x="288" y="48" width="176" height="176" rx="20" ry="20"
													  style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}} />
												<rect x="48" y="288" width="176" height="176" rx="20" ry="20"
													  style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}} />
												<rect x="288" y="288" width="176" height="176" rx="20" ry="20"
													  style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}} />
											</svg>
										</div>
										<h3 className="h4">Scalable</h3>
										<p className="mb-0 text-muted">Effortlessly expand your business with our scalable solution that adapts to your evolving needs.</p>
									</div>
								</div>
								<div
									className="three-features1 pb-4 position-relative features-end text-start text-lg-end">
									{/*<svg xmlns="http://www.w3.org/2000/svg" className="features-icon text-dark"*/}
									{/*	 width="3.5rem" height="3.5rem" fill="currentColor" viewBox="0 0 512 512">*/}
									{/*	<rect x="48" y="96" width="416" height="320" rx="56" ry="56"*/}
									{/*		  style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}} />*/}
									{/*	<line x1="48" y1="192" x2="464" y2="192"*/}
									{/*		  style={{fill:"none",stroke:"currentColor",strokeLinejoin:"round",strokeWidth:"60px"}} />*/}
									{/*	<rect x="128" y="300" width="48" height="20"*/}
									{/*		  style={{fill:"none",stroke:"currentColor",strokeLinejoin:"round",strokeWidth:"60px"}} />*/}
									{/*</svg>*/}

									{/*<h3 className="h5">Billing</h3>*/}
									{/*<p>Simplify payments and financial tasks with our integrated billing feature, enhancing your scheduling process.</p>*/}
									<div className="text-center">
										<div className="display-4 text-primary mb-3">
											<svg xmlns="http://www.w3.org/2000/svg" className="text-dark"
												 width="48px" height="48px" fill="currentColor" viewBox="0 0 512 512">
												<rect x="48" y="96" width="416" height="320" rx="56" ry="56"
													  style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}} />
												<line x1="48" y1="192" x2="464" y2="192"
													  style={{fill:"none",stroke:"currentColor",strokeLinejoin:"round",strokeWidth:"60px"}} />
												<rect x="128" y="300" width="48" height="20"
													  style={{fill:"none",stroke:"currentColor",strokeLinejoin:"round",strokeWidth:"60px"}} />
											</svg>
										</div>
										<h3 className="h4">Billing</h3>
										<p className="mb-0 text-muted">Simplify payments and financial tasks with our integrated billing feature, enhancing your scheduling process.</p>
									</div>
								</div>
								<div
									className="three-features1 pb-4 position-relative features-end text-start text-lg-end">
									{/*<svg xmlns="http://www.w3.org/2000/svg" className="features-icon text-warning"*/}
									{/*	 width="3.5rem" height="3.5rem" fill="currentColor" viewBox="0 0 512 512">*/}
									{/*	<rect x="128" y="16" width="256" height="480" rx="48" ry="48"*/}
									{/*		  style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}} />*/}
									{/*	<path*/}
									{/*		d="M176,16h24a8,8,0,0,1,8,8h0a16,16,0,0,0,16,16h64a16,16,0,0,0,16-16h0a8,8,0,0,1,8-8h24"*/}
									{/*		style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}} />*/}
									{/*</svg>*/}

									{/*<h3 className="h5">Notifications</h3>*/}
									{/*<p>Stay connected with automatic notifications, ensuring timely updates and improved engagement.</p>*/}
									<div className="text-center">
										<div className="display-4 text-primary mb-3">
											<svg xmlns="http://www.w3.org/2000/svg" className="text-warning"
												 width="48px" height="48px" fill="currentColor" viewBox="0 0 512 512">
												<rect x="128" y="16" width="256" height="480" rx="48" ry="48"
													  style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}} />
												<path
													d="M176,16h24a8,8,0,0,1,8,8h0a16,16,0,0,0,16,16h64a16,16,0,0,0,16-16h0a8,8,0,0,1,8-8h24"
													style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}} />
											</svg>
										</div>
										<h3 className="h4">Notifications</h3>
										<p className="mb-0 text-muted">Stay connected with automatic notifications, ensuring timely updates and improved engagement.</p>
									</div>
								</div>
							</div>

							<div className="col-6 col-lg-4 order-lg-3" data-aos="fade-left" data-aos-delay="100">
								<div
									className="three-features1 pb-4 position-relative text-start text-md-end text-lg-start">
								{/*	<svg xmlns="http://www.w3.org/2000/svg" className="features-icon text-secondary"*/}
								{/*		 width="3.5rem" height="3.5rem" fill="currentColor" viewBox="0 0 512 512">*/}
								{/*		<path*/}
								{/*			d="M434.8,137.65,285.44,69.55c-16.19-7.4-42.69-7.4-58.88,0L77.3,137.65c-17.6,8-17.6,21.09,0,29.09l148,67.5c16.89,7.7,44.69,7.7,61.58,0l148-67.5C452.4,158.74,452.4,145.64,434.8,137.65Z"*/}
								{/*			style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}} />*/}
								{/*		<path*/}
								{/*			d="M160,308.52,77.3,345.63c-17.6,8-17.6,21.1,0,29.1l148,67.5c16.89,7.69,44.69,7.69,61.58,0l148-67.5c17.6-8,17.6-21.1,0-29.1l-79.94-38.47"*/}
								{/*			style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}} />*/}
								{/*		<path*/}
								{/*			d="M160,204.48,77.2,241.64c-17.6,8-17.6,21.1,0,29.1l148,67.49c16.89,7.7,44.69,7.7,61.58,0l148-67.49c17.7-8,17.7-21.1.1-29.1L352,204.48"*/}
								{/*			style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}} />*/}
								{/*	</svg>*/}

								{/*	<h3 className="h5">Integrations</h3>*/}
								{/*	<p>Integrations seamlessly unite with your favorite tools, amplifying efficiency and simplifying your workflow.</p>*/}
									<div className="text-center">
										<div className="display-4 text-primary mb-3">
											<svg xmlns="http://www.w3.org/2000/svg" className="features-icon text-secondary"
												 width="48px" height="48px" fill="currentColor" viewBox="0 0 512 512">
												<path
													d="M434.8,137.65,285.44,69.55c-16.19-7.4-42.69-7.4-58.88,0L77.3,137.65c-17.6,8-17.6,21.09,0,29.09l148,67.5c16.89,7.7,44.69,7.7,61.58,0l148-67.5C452.4,158.74,452.4,145.64,434.8,137.65Z"
													style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}} />
												<path
													d="M160,308.52,77.3,345.63c-17.6,8-17.6,21.1,0,29.1l148,67.5c16.89,7.69,44.69,7.69,61.58,0l148-67.5c17.6-8,17.6-21.1,0-29.1l-79.94-38.47"
													style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}} />
												<path
													d="M160,204.48,77.2,241.64c-17.6,8-17.6,21.1,0,29.1l148,67.49c16.89,7.7,44.69,7.7,61.58,0l148-67.49c17.7-8,17.7-21.1.1-29.1L352,204.48"
													style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}} />
											</svg>
										</div>
										<h3 className="h4">Integrations</h3>
										<p className="mb-0 text-muted">Integrations seamlessly unite with your favorite tools, amplifying efficiency and simplifying your workflow.</p>
									</div>
								</div>
								<div
									className="three-features1 pb-4 position-relative text-start text-md-end text-lg-start">

									{/*<svg xmlns="http://www.w3.org/2000/svg" className="features-icon text-primary"*/}
									{/*	 width="3.5rem" height="3.5rem" fill="currentColor" viewBox="0 0 512 512">*/}
									{/*	<path d="M32,32V464a16,16,0,0,0,16,16H480"*/}
									{/*		  style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}} />*/}
									{/*	<rect x="96" y="224" width="80" height="192" rx="20" ry="20"*/}
									{/*		  style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}} />*/}
									{/*	<rect x="240" y="176" width="80" height="240" rx="20" ry="20"*/}
									{/*		  style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}} />*/}
									{/*	<rect x="383.64" y="112" width="80" height="304" rx="20" ry="20"*/}
									{/*		  style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}} />*/}
									{/*</svg>*/}

									{/*<h3 className="h5">Reports</h3>*/}
									{/*<p>Gain valuable insights into appointments, trends, and customer interactions through our powerful reporting feature.</p>*/}
									<div className="text-center">
										<div className="display-4 text-primary mb-3">
											<svg xmlns="http://www.w3.org/2000/svg" className="text-primary"
												 width="48px" height="48px" fill="currentColor" viewBox="0 0 512 512">
												<path d="M32,32V464a16,16,0,0,0,16,16H480"
													  style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}} />
												<rect x="96" y="224" width="80" height="192" rx="20" ry="20"
													  style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}} />
												<rect x="240" y="176" width="80" height="240" rx="20" ry="20"
													  style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}} />
												<rect x="383.64" y="112" width="80" height="304" rx="20" ry="20"
													  style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}} />
											</svg>
										</div>
										<h3 className="h4">Reports</h3>
										<p className="mb-0 text-muted">Gain valuable insights into appointments, trends, and customer interactions through our powerful reporting feature.</p>
									</div>
								</div>
								<div
									className="three-features4 pb-4 position-relative text-start text-md-end text-lg-start">
									{/*<svg xmlns="http://www.w3.org/2000/svg" className="features-icon text-success"*/}
									{/*	 width="3.5rem" height="3.5rem" fill="currentColor" viewBox="0 0 512 512">*/}
									{/*	<rect x="48" y="96" width="416" height="304" rx="32.14" ry="32.14"*/}
									{/*		  style={{fill:"none",stroke:"currentColor",strokeLinejoin:"round",strokeWidth:"32px"}} />*/}
									{/*	<line x1="16" y1="416" x2="496" y2="416"*/}
									{/*		  style={{stroke:"currentColor",strokeLinecap:"round",strokeMiterlimit:"10",strokeWidth:"32px"}} />*/}
									{/*</svg>*/}

									{/*<h3 className="h5">Booking View</h3>*/}
									{/*<p>Our user-friendly booking view, empowers your customers to book and manage their appointments, saving you time.</p>*/}
									<div className="text-center">
										<div className="display-4 text-primary mb-3">
											<svg xmlns="http://www.w3.org/2000/svg" className="text-success"
												 width="48px" height="48px" fill="currentColor" viewBox="0 0 512 512">
												<rect x="48" y="96" width="416" height="304" rx="32.14" ry="32.14"
													  style={{fill:"none",stroke:"currentColor",strokeLinejoin:"round",strokeWidth:"32px"}} />
												<line x1="16" y1="416" x2="496" y2="416"
													  style={{stroke:"currentColor",strokeLinecap:"round",strokeMiterlimit:"10",strokeWidth:"32px"}} />
											</svg>
										</div>
										<h3 className="h4">Booking View</h3>
										<p className="mb-0 text-muted">Our user-friendly booking view, empowers your customers to book and manage their appointments, saving you time.</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				{/* =========={ SERVICE }==========  */}
				<div id="service" className="section pt-8 pb-3 bg-light-dark">
					<div className="container">
						<div className="row text-center">
							<div className="col-12">
								<div className="mb-5">
									<h2 className="h2 fw-bold">Bots That Work For You</h2>
									{/*<hr class="divider my-4 mx-auto bg-warning border-warning">*/}
									<p className="lead text-muted">Innovative BOT Solutions for Every Industry.</p>
								</div>
							</div>
						</div>

						{/* row */}
						<div className="row">
							<div className="col-lg-6 col-sm-6 d-flex" data-aos="fade-up">
								{/* service block */}
								<div className="five-service hover-box-up text-center mb-7 d-flex align-self-stretch">
									<div className="service-icon bg-info text-white border">
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="32px" height="32px" fill="currentColor" viewBox="0 0 512 512"><circle cx={248} cy={56} r={40} style={{fill: 'none', stroke: 'currentColor', strokeMiterlimit: 10, strokeWidth: '32px'}} /><path d="M199.3,295.62h0l-30.4,172.2a24,24,0,0,0,19.5,27.8,23.76,23.76,0,0,0,27.6-19.5l21-119.9v.2s5.2-32.5,17.5-32.5h3.1c12.5,0,17.5,32.5,17.5,32.5v-.1l21,119.9a23.92,23.92,0,1,0,47.1-8.4l-30.4-172.2-4.9-29.7c-2.9-18.1-4.2-47.6.5-59.7,4-10.4,14.13-14.2,23.2-14.2H424a24,24,0,0,0,0-48H88a24,24,0,0,0,0,48h92.5c9.23,0,19.2,3.8,23.2,14.2,4.7,12.1,3.4,41.6.5,59.7Z" style={{fill: 'none', stroke: 'currentColor', strokeMiterlimit: 10, strokeWidth: '32px'}} /></svg>
					  {/* <i class="fas fa-desktop text-primary"></i>  */}
                  </span>
									</div>
									<div className="service-content bg-body pt-5 pb-4 px-4 rounded-3 shadow-sm">
										<h3 className="h5 mt-3">Health, Mind and Beauty</h3>
										<p className="mb-4 text-muted">Seamlessly manage appointments and elevate client satisfaction for your health, mind or beauty business.</p>
										<Link to="/industries/health-and-beauty" className="read-more bg-body text-body border-dark-light" title="Read More">
											{/* <i class="fa fa-plus"></i> */}
											<svg className="bi bi-plus" width="32px" height="32px" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
												<path fillRule="evenodd" d="M8 3.5a.5.5 0 01.5.5v4a.5.5 0 01-.5.5H4a.5.5 0 010-1h3.5V4a.5.5 0 01.5-.5z" clipRule="evenodd" />
												<path fillRule="evenodd" d="M7.5 8a.5.5 0 01.5-.5h4a.5.5 0 010 1H8.5V12a.5.5 0 01-1 0V8z" clipRule="evenodd" />
											</svg>
										</Link>
									</div>
								</div>{/* end service block */}
							</div>
							<div className="col-lg-6 col-sm-6 d-flex" data-aos="fade-up" data-aos-delay={100}>
								{/* service block */}
								<div className="five-service hover-box-up text-center mb-7 d-flex align-self-stretch">
									<div className="service-icon bg-success text-white border">
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="32px" height="32px" fill="currentColor" viewBox="0 0 512 512"><rect x={48} y={64} width={416} height={384} rx={48} ry={48} style={{fill: 'none', stroke: 'currentColor', strokeLinejoin: 'round', strokeWidth: '32px'}} /><path d="M397.82,64H114.18C77.69,64,48,94.15,48,131.2V176H64c0-16,16-32,32-32H416c16,0,32,16,32,32h16V131.2C464,94.15,434.31,64,397.82,64Z" /></svg>
					  {/* <i class="fas fa-chart-pie text-primary"></i>  */}
                  </span>
									</div>
									<div className="service-content bg-body pt-5 pb-4 px-4 rounded-3 shadow-sm">
										<h3 className="h5 mt-3">Professional Services</h3>
										<p className="mb-4 text-muted">Professional service firms can automate appointment scheduling, freeing up valuable time for client-focused tasks.</p>
										<Link to="/industries/professional-services" className="read-more bg-body text-body border-dark-light" title="Read More">
											{/* <i class="fa fa-plus"></i> */}
											<svg className="bi bi-plus" width="32px" height="32px" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
												<path fillRule="evenodd" d="M8 3.5a.5.5 0 01.5.5v4a.5.5 0 01-.5.5H4a.5.5 0 010-1h3.5V4a.5.5 0 01.5-.5z" clipRule="evenodd" />
												<path fillRule="evenodd" d="M7.5 8a.5.5 0 01.5-.5h4a.5.5 0 010 1H8.5V12a.5.5 0 01-1 0V8z" clipRule="evenodd" />
											</svg>
										</Link>
									</div>
								</div>{/* end service block */}
							</div>
							<div className="col-lg-6 col-sm-6 d-flex" data-aos="fade-up" data-aos-delay={200}>
								{/* service block */}
								<div className="five-service hover-box-up text-center mb-7 d-flex align-self-stretch">
									<div className="service-icon bg-warning text-white border">
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="32px" height="32px" fill="currentColor" viewBox="0 0 512 512"><rect x={80} y={112} width={352} height={192} rx={32} ry={32} style={{fill: 'none', stroke: 'currentColor', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '32px'}} /><rect x={80} y={304} width={352} height={128} rx={32} ry={32} style={{fill: 'none', stroke: 'currentColor', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '32px'}} /><path d="M400,112H112A32.09,32.09,0,0,1,80,80h0a32.09,32.09,0,0,1,32-32H400a32.09,32.09,0,0,1,32,32h0A32.09,32.09,0,0,1,400,112Z" style={{fill: 'none', stroke: 'currentColor', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '32px'}} /><path d="M144,432v22a10,10,0,0,1-10,10H106a10,10,0,0,1-10-10V432Z" style={{fill: 'none', stroke: 'currentColor', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '32px'}} /><path d="M416,432v22a10,10,0,0,1-10,10H378a10,10,0,0,1-10-10V432Z" style={{fill: 'none', stroke: 'currentColor', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '32px'}} /><circle cx={368} cy={368} r={16} style={{fill: 'none', stroke: 'currentColor', strokeLinejoin: 'round', strokeWidth: '32px'}} /><circle cx={144} cy={368} r={16} style={{fill: 'none', stroke: 'currentColor', strokeLinejoin: 'round', strokeWidth: '32px'}} /><line x1={256} y1={112} x2={256} y2={304} style={{fill: 'none', stroke: 'currentColor', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '32px'}} /><line x1={80} y1={80} x2={80} y2={368} style={{fill: 'none', stroke: 'currentColor', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '32px'}} /><line x1={432} y1={80} x2={432} y2={368} style={{fill: 'none', stroke: 'currentColor', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '32px'}} /></svg>
					  {/* <i class="text-primary fas fa-search"></i> */}
                  </span>
									</div>
									<div className="service-content bg-body pt-5 pb-4 px-4 rounded-3 shadow-sm">
										<h3 className="h5 mt-3">Education</h3>
										<p className="mb-4 text-muted">Simplify class scheduling and appointment management for educational institutions, enhancing organization and accessibility.</p>
										<Link to="/industries/education" className="read-more bg-body text-body border-dark-light" title="Read More">
											{/* <i class="fa fa-plus"></i> */}
											<svg className="bi bi-plus" width="32px" height="32px" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
												<path fillRule="evenodd" d="M8 3.5a.5.5 0 01.5.5v4a.5.5 0 01-.5.5H4a.5.5 0 010-1h3.5V4a.5.5 0 01.5-.5z" clipRule="evenodd" />
												<path fillRule="evenodd" d="M7.5 8a.5.5 0 01.5-.5h4a.5.5 0 010 1H8.5V12a.5.5 0 01-1 0V8z" clipRule="evenodd" />
											</svg>
										</Link>
									</div>
								</div>{/* end service block */}
							</div>
							<div className="col-lg-6 col-sm-6 d-flex" data-aos="fade-up">
								{/* service block */}
								<div className="five-service hover-box-up text-center mb-7 d-flex align-self-stretch">
									<div className="service-icon bg-danger text-white border">
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="32px" height="32px" fill="currentColor" viewBox="0 0 512 512"><path d="M277.42,247a24.68,24.68,0,0,0-4.08-5.47L255,223.44a21.63,21.63,0,0,0-6.56-4.57,20.93,20.93,0,0,0-23.28,4.27c-6.36,6.26-18,17.68-39,38.43C146,301.3,71.43,367.89,37.71,396.29a16,16,0,0,0-1.09,23.54l39,39.43a16.13,16.13,0,0,0,23.67-.89c29.24-34.37,96.3-109,136-148.23,20.39-20.06,31.82-31.58,38.29-37.94A21.76,21.76,0,0,0,277.42,247Z" style={{fill: 'none', stroke: 'currentColor', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '32px'}} /><path d="M478.43,201l-34.31-34a5.44,5.44,0,0,0-4-1.59,5.59,5.59,0,0,0-4,1.59h0a11.41,11.41,0,0,1-9.55,3.27c-4.48-.49-9.25-1.88-12.33-4.86-7-6.86,1.09-20.36-5.07-29a242.88,242.88,0,0,0-23.08-26.72c-7.06-7-34.81-33.47-81.55-52.53a123.79,123.79,0,0,0-47-9.24c-26.35,0-46.61,11.76-54,18.51-5.88,5.32-12,13.77-12,13.77A91.29,91.29,0,0,1,202.35,77a79.53,79.53,0,0,1,23.28-1.49C241.19,76.8,259.94,84.1,270,92c16.21,13,23.18,30.39,24.27,52.83.8,16.69-15.23,37.76-30.44,54.94a7.85,7.85,0,0,0,.4,10.83l21.24,21.23a8,8,0,0,0,11.14.1c13.93-13.51,31.09-28.47,40.82-34.46s17.58-7.68,21.35-8.09A35.71,35.71,0,0,1,380.08,194a13.65,13.65,0,0,1,3.08,2.38c6.46,6.56,6.07,17.28-.5,23.74l-2,1.89a5.5,5.5,0,0,0,0,7.84l34.31,34a5.5,5.5,0,0,0,4,1.58,5.65,5.65,0,0,0,4-1.58L478.43,209A5.82,5.82,0,0,0,478.43,201Z" style={{fill: 'none', stroke: 'currentColor', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '32px'}} /></svg>
					  {/* <i class="text-primary fab fa-facebook"></i> */}
                  </span>
									</div>
									<div className="service-content bg-body pt-5 pb-4 px-4 rounded-3 shadow-sm">
										<h3 className="h5 mt-3">Trade Businesses</h3>
										<p className="mb-4 text-muted">Empowering trade businesses to efficiently manage appointments and job assignments, ensuring a smooth and organized workflow.</p>
										<Link to="/industries/trade-business" className="read-more bg-body text-body border-dark-light" title="Read More">
											{/* <i class="fa fa-plus"></i> */}
											<svg className="bi bi-plus" width="32px" height="32px" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
												<path fillRule="evenodd" d="M8 3.5a.5.5 0 01.5.5v4a.5.5 0 01-.5.5H4a.5.5 0 010-1h3.5V4a.5.5 0 01.5-.5z" clipRule="evenodd" />
												<path fillRule="evenodd" d="M7.5 8a.5.5 0 01.5-.5h4a.5.5 0 010 1H8.5V12a.5.5 0 01-1 0V8z" clipRule="evenodd" />
											</svg>
										</Link>
									</div>
								</div>{/* end service block */}
							</div>
						</div>{/* end row */}
						<div className="text-center mb-4"><Link to="/industries/general">Don't see your business? With hundreds of customizations, we have a solution for your business!</Link></div>
					</div>
				</div>{/* End Service */}

				<div id="faq" className="section py-6 pt-md-7 pb-md-6 bg-light2 bg-gradient-primary">
					<div className="container">
						<header className="text-center mx-auto mb-5">
							<h2 className="h2 fw-bold text-white">Things people ask a-bot.</h2>
							{/*<hr class="divider my-4 bg-warning border-warning">*/}
							{/*<p class="lead text-muted">Most Popular Question from New Client!</p>*/}
						</header>
						<div className="row">
							<div className="accordion-list col-12">
								<ul className="row list-unstyled">
									<li className="col-md-6" data-aos="fade-up">
										<div className="list-group-item border-0 shadow-sm mb-4">
											<svg xmlns="http://www.w3.org/2000/svg" className="me-2" width="20"
												 height="20" viewBox="0 0 512 512">
												<path stroke="currentColor"
													  d="M256,80A176,176,0,1,0,432,256,176,176,0,0,0,256,80Z"
													  style={{fill:"none",strokeMiterlimit:"10",strokeWidth:"32px"}} />
												<path stroke="currentColor"
													  d="M200,202.29s.84-17.5,19.57-32.57C230.68,160.77,244,158.18,256,158c10.93-.14,20.69,1.67,26.53,4.45,10,4.76,29.47,16.38,29.47,41.09,0,26-17,37.81-36.37,50.8S251,281.43,251,296"
													  style={{fill:"none",strokeLinecap:"round",strokeMiterlimit:"10",strokeWidth:"28px"}} />
												<circle cx="250" cy="348" r="20"/>
											</svg>

											<a data-bs-toggle="collapse" className="collapsed text-secondary py-2"
											   href="#accordion-list-1" aria-expanded="false">
												<strong>Is my data mine?</strong>
											</a>
											<div id="accordion-list-1" className="collapse p-3"
												 data-bs-parent=".accordion-list">
												<p className="mb-0 text-body">
													Yes, your data belongs to you and you have the ability to export it at any time.
												</p>
											</div>
										</div>
									</li>
									<li className="col-md-6" data-aos="fade-up" data-aos-delay="50">
										<div className="list-group-item border-0 shadow-sm mb-4">
											<svg xmlns="http://www.w3.org/2000/svg" className="me-2" width="20"
												 height="20" viewBox="0 0 512 512">
												<path stroke="currentColor"
													  d="M256,80A176,176,0,1,0,432,256,176,176,0,0,0,256,80Z"
													  style={{fill:"none",strokeMiterlimit:"10",strokeWidth:"32px"}} />
												<path stroke="currentColor"
													  d="M200,202.29s.84-17.5,19.57-32.57C230.68,160.77,244,158.18,256,158c10.93-.14,20.69,1.67,26.53,4.45,10,4.76,29.47,16.38,29.47,41.09,0,26-17,37.81-36.37,50.8S251,281.43,251,296"
													  style={{fill:"none",strokeLinecap:"round",strokeMiterlimit:"10",strokeWidth:"28px"}} />
												<circle cx="250" cy="348" r="20"/>
											</svg>

											<a data-bs-toggle="collapse" className="collapsed text-secondary py-2"
											   href="#accordion-list-2" aria-expanded="false">
												<strong>Do you have any integration with payment systems?</strong>
											</a>
											<div id="accordion-list-2" className="collapse p-3"
												 data-bs-parent=".accordion-list">
												<p className="mb-0 text-body">
													Yes, we currently integrate with Stripe, a widely used and trusted payment processing platform.
												</p>
											</div>
										</div>
									</li>
									<li className="col-md-6" data-aos="fade-up" data-aos-delay="100">
										<div className="list-group-item border-0 shadow-sm mb-4">
											<svg xmlns="http://www.w3.org/2000/svg" className="me-2" width="20"
												 height="20" viewBox="0 0 512 512">
												<path stroke="currentColor"
													  d="M256,80A176,176,0,1,0,432,256,176,176,0,0,0,256,80Z"
													  style={{fill:"none",strokeMiterlimit:"10",strokeWidth:"32px"}} />
												<path stroke="currentColor"
													  d="M200,202.29s.84-17.5,19.57-32.57C230.68,160.77,244,158.18,256,158c10.93-.14,20.69,1.67,26.53,4.45,10,4.76,29.47,16.38,29.47,41.09,0,26-17,37.81-36.37,50.8S251,281.43,251,296"
													  style={{fill:"none",strokeLinecap:"round",strokeMiterlimit:"10",strokeWidth:"28px"}} />
												<circle cx="250" cy="348" r="20"/>
											</svg>

											<a data-bs-toggle="collapse" className="collapsed text-secondary py-2"
											   href="#accordion-list-3" aria-expanded="false">
													<strong>Is it possible to migrate my data from my current system to yours?</strong>
											</a>
											<div id="accordion-list-3" className="collapse p-3"
												 data-bs-parent=".accordion-list">
												<p className="mb-0 text-body">
													Yes, as long as you can export your data from the other system, our support team will assist you with the migration process.
												</p>
											</div>
										</div>
									</li>
									<li className="col-md-6" data-aos="fade-up" data-aos-delay="200">
										<div className="list-group-item border-0 shadow-sm mb-4">
											<svg xmlns="http://www.w3.org/2000/svg" className="me-2" width="20"
												 height="20" viewBox="0 0 512 512">
												<path stroke="currentColor"
													  d="M256,80A176,176,0,1,0,432,256,176,176,0,0,0,256,80Z"
													  style={{fill:"none",strokeMiterlimit:"10",strokeWidth:"32px"}} />
												<path stroke="currentColor"
													  d="M200,202.29s.84-17.5,19.57-32.57C230.68,160.77,244,158.18,256,158c10.93-.14,20.69,1.67,26.53,4.45,10,4.76,29.47,16.38,29.47,41.09,0,26-17,37.81-36.37,50.8S251,281.43,251,296"
													  style={{fill:"none",strokeLinecap:"round",strokeMiterlimit:"10",strokeWidth:"28px"}} />
												<circle cx="250" cy="348" r="20"/>
											</svg>

											<a data-bs-toggle="collapse" className="collapsed text-secondary py-2"
											   href="#accordion-list-5" aria-expanded="false">
												<strong>Is a credit card required to sign up for your service?</strong>
											</a>
											<div id="accordion-list-5" className="collapse p-3"
												 data-bs-parent=".accordion-list">
												<p className="mb-0 text-body">
													No, signing up for our service and creating workspaces is completely free. A credit card number will only be required if you choose to enable any paid features.
												</p>
											</div>
										</div>
									</li>
									<li className="col-md-6" data-aos="fade-up" data-aos-delay="250">
										<div className="list-group-item border-0 shadow-sm mb-4">
											<svg xmlns="http://www.w3.org/2000/svg" className="me-2" width="20"
												 height="20" viewBox="0 0 512 512">
												<path stroke="currentColor"
													  d="M256,80A176,176,0,1,0,432,256,176,176,0,0,0,256,80Z"
													  style={{fill:"none",strokeMiterlimit:"10",strokeWidth:"32px"}} />
												<path stroke="currentColor"
													  d="M200,202.29s.84-17.5,19.57-32.57C230.68,160.77,244,158.18,256,158c10.93-.14,20.69,1.67,26.53,4.45,10,4.76,29.47,16.38,29.47,41.09,0,26-17,37.81-36.37,50.8S251,281.43,251,296"
													  style={{fill:"none",strokeLinecap:"round",strokeMiterlimit:"10",strokeWidth:"28px"}} />
												<circle cx="250" cy="348" r="20"/>
											</svg>

											<a data-bs-toggle="collapse" className="collapsed text-secondary py-2"
											   href="#accordion-list-6" aria-expanded="false">
												<strong>Is it possible to cancel my workspace?</strong>
											</a>
											<div id="accordion-list-6" className="collapse p-3"
												 data-bs-parent=".accordion-list">
												<p className="mb-0 text-body">
													Yes, while we are sorry to see you go, we understand that circumstances change. You have the flexibility to cancel your workspace at any time.
												</p>
											</div>
										</div>
									</li>
									<li className="col-md-6" data-aos="fade-up" data-aos-delay="300">
										<div className="list-group-item border-0 shadow-sm mb-4">
											<svg xmlns="http://www.w3.org/2000/svg" className="me-2" width="20"
												 height="20" viewBox="0 0 512 512">
												<path stroke="currentColor"
													  d="M256,80A176,176,0,1,0,432,256,176,176,0,0,0,256,80Z"
													  style={{fill:"none",strokeMiterlimit:"10",strokeWidth:"32px"}} />
												<path stroke="currentColor"
													  d="M200,202.29s.84-17.5,19.57-32.57C230.68,160.77,244,158.18,256,158c10.93-.14,20.69,1.67,26.53,4.45,10,4.76,29.47,16.38,29.47,41.09,0,26-17,37.81-36.37,50.8S251,281.43,251,296"
													  style={{fill:"none",strokeLinecap:"round",strokeMiterlimit:"10",strokeWidth:"28px"}}/>
												<circle cx="250" cy="348" r="20"/>
											</svg>

											<a data-bs-toggle="collapse" className="collapsed text-secondary py-2"
											   href="#accordion-list-7" aria-expanded="false">
												<strong>Why are you so awesome?</strong>
											</a>
											<div id="accordion-list-7" className="collapse p-3"
												 data-bs-parent=".accordion-list">
												<p className="mb-0 text-body">
													Thank you, but we don't take all the credit. Our advanced technology and hardworking robots are what makes us great.
												</p>
											</div>
										</div>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>

				<div id="knowledge-base" className="section bg-light">
					<div className="container-fluid p-0">
						<div className="row g-0">
							<div className="col-lg-12 order-lg-1 my-auto showcase-text text-center text-md-start">
								<div className="position-relative overflow-hidden">
									<figure className="scribble scale-3 opacity-10 bottom-0 z-index-n1">
										<svg className="text-light" width="200" height="200" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
											<path fill="currentColor" d="M42.5,-66.2C57.1,-56.7,72.5,-48.4,81.1,-35.3C89.8,-22.2,91.8,-4.4,89.6,13C87.3,30.4,80.7,47.4,69.5,60.1C58.3,72.9,42.4,81.5,25.9,84.6C9.5,87.8,-7.4,85.4,-22.7,79.8C-37.9,74.1,-51.5,65.2,-60.9,53.3C-70.4,41.4,-75.8,26.6,-79,10.8C-82.1,-5,-83.1,-21.7,-77.7,-36.4C-72.4,-51,-60.7,-63.7,-46.7,-73.5C-32.7,-83.3,-16.4,-90.1,-1.2,-88.2C13.9,-86.3,27.8,-75.7,42.5,-66.2Z" transform="translate(100 100)" />
										</svg>
									</figure>

									<figure className="scribble scale-4 opacity-10 bottom-0 z-index-n1">
										<svg className="text-body" width="200" height="200" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
											<path fill="currentColor" d="M42.5,-66.2C57.1,-56.7,72.5,-48.4,81.1,-35.3C89.8,-22.2,91.8,-4.4,89.6,13C87.3,30.4,80.7,47.4,69.5,60.1C58.3,72.9,42.4,81.5,25.9,84.6C9.5,87.8,-7.4,85.4,-22.7,79.8C-37.9,74.1,-51.5,65.2,-60.9,53.3C-70.4,41.4,-75.8,26.6,-79,10.8C-82.1,-5,-83.1,-21.7,-77.7,-36.4C-72.4,-51,-60.7,-63.7,-46.7,-73.5C-32.7,-83.3,-16.4,-90.1,-1.2,-88.2C13.9,-86.3,27.8,-75.7,42.5,-66.2Z" transform="translate(100 100)" />
										</svg>
									</figure>

									<figure className="scribble scale-5 opacity-10 bottom-0 z-index-n1">
										<svg className="text-body" width="200" height="200" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
											<path fill="currentColor" d="M42.5,-66.2C57.1,-56.7,72.5,-48.4,81.1,-35.3C89.8,-22.2,91.8,-4.4,89.6,13C87.3,30.4,80.7,47.4,69.5,60.1C58.3,72.9,42.4,81.5,25.9,84.6C9.5,87.8,-7.4,85.4,-22.7,79.8C-37.9,74.1,-51.5,65.2,-60.9,53.3C-70.4,41.4,-75.8,26.6,-79,10.8C-82.1,-5,-83.1,-21.7,-77.7,-36.4C-72.4,-51,-60.7,-63.7,-46.7,-73.5C-32.7,-83.3,-16.4,-90.1,-1.2,-88.2C13.9,-86.3,27.8,-75.7,42.5,-66.2Z" transform="translate(100 100)" />
										</svg>
									</figure>

									<figure className="scribble scale-6 opacity-10 bottom-0 z-index-n1">
										<svg className="text-body" width="200" height="200" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
											<path fill="currentColor" d="M42.5,-66.2C57.1,-56.7,72.5,-48.4,81.1,-35.3C89.8,-22.2,91.8,-4.4,89.6,13C87.3,30.4,80.7,47.4,69.5,60.1C58.3,72.9,42.4,81.5,25.9,84.6C9.5,87.8,-7.4,85.4,-22.7,79.8C-37.9,74.1,-51.5,65.2,-60.9,53.3C-70.4,41.4,-75.8,26.6,-79,10.8C-82.1,-5,-83.1,-21.7,-77.7,-36.4C-72.4,-51,-60.7,-63.7,-46.7,-73.5C-32.7,-83.3,-16.4,-90.1,-1.2,-88.2C13.9,-86.3,27.8,-75.7,42.5,-66.2Z" transform="translate(100 100)" />
										</svg>
									</figure>

									<div className="px-4 px-lg-6 mt-5">
										<div className="row justify-content-center">
											<div className="col-11 col-sm-8 col-lg-6 order-xl-1 align-self-end"
												 data-aos="fade-right">
												<div className="px-xl-5 text-center">
													<img className="img-fluid" src="/src/logo/KnowledgeBaseScreenshot.png" alt="knowledge base" />
												</div>
											</div>

											<div
												className="col-11 col-sm-8 col-lg-12 col-xl-6 order-xl-2 text-center text-md-start"
												data-aos="flip-up">
												<h3 className="fw-bold mt-4 mb-4">Bots, Docs & Beyond</h3>
												<p className="">Explore our BotScheduling Docs page, your go-to support resource, providing in-depth insights, troubleshooting guidance, and a user-friendly layout to help you make the most of our products and services.</p>
												<div className="mb-4">
													<Link className="btn btn-secondary btn-pill btn-sm" to="/docs">
														MORE
													</Link>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</main>

			<Footer />
		</Fragment>
	)
}

export default Home;