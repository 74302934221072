import React, { Fragment, useState } from 'react';
import Header from './fragments/Header';
import Footer from './fragments/Footer';

const Pricing = () => {
	const [checked, setChecked] = useState(false);

	const handleChange = () => {
		setChecked(!checked);
	};

	return (
		<Fragment>
			<Header forceLightMenu={true} />

			<main id="content">
				<div style={{height: "92px"}} />

				<div id="pricing" className="section pt-6 pt-md-7 pb-4 pb-md-5 bg-light">
					<div className="container">
						<header className="text-center mx-auto mb-5">
							<h2 className="h2 fw-bold">Plans that grow with you!</h2>
							<hr className="divider my-4 bg-warning border-warning" />
							<p className="lead text-muted">Begin using our service for no cost or upgrade to a paid plan for additional features.</p>
						</header>

						<div className="text-center">
							<div className="slideToggle mb-5 mb-lg-6">
								<label className="form-switch">
									<span className="beforeinput">Annualy</span>
									<input type="checkbox" id="js-checkbox" className="js-checkbox" checked={checked}
										   onChange={handleChange} />
									<i/>
									<span className="afterinput">Monthly</span>
								</label>
							</div>
						</div>

						<div className="row justify-content-center">
							<div className="col-12 col-md-6 col-lg-3" data-aos="fade-up">
								<div className="px-xl-2">
									<div className="pricing-table-two shadow-sm rounded-3 mb-5">
										<header className="p-3">
											<h3 className="fw-bold h4">Free</h3>
											<p className="sub-head text-muted">Try it out</p>
											<div className="line-after bg-gradient-primary mb-4" />
											<div className="pricing-fee">
												<h4 className={`text-primary ${checked ? "d-none" : "d-block"}`}>$0.00</h4>
												<h4 className={`text-primary ${checked ? "d-block" : "d-none"}`}>$0.00</h4>
											</div>
										</header>

										<div className="p-3">
											<p className="small bold pricing__price--small">Includes</p>
											<p>100 APPOINTMENTS</p>
											{/*<p>1 BOOKING INTERFACE</p>*/}
											<p>1 USER</p>
											<p>TAGS / GROUPS</p>
											<p>KNOWLEDGE BASE</p>
											<div className="d-grid mt-5"><a
												className="btn btn-secondary btn-pill btn-block"
												href="https://app.botscheduling.com/create" target="_blank">GET
												STARTED</a></div>
										</div>
									</div>
								</div>
							</div>

							<div className="col-12 col-md-6 col-lg-3" data-aos="fade-up">
								<div className="px-xl-2">
									<div className="pricing-table-two shadow-sm rounded-3 mt-lg-n4 mb-5">
										<div className="ribbon ribbon-md ribbon-end">
											<span className="bg-warning text-dark">Popular</span>
										</div>
										<header className="p-3">
											<h3 className=" fw-bold h4">Starter</h3>
											<p className="sub-head text-muted">Personal</p>
											<div className="line-after bg-gradient-primary mb-4" />
											<div className="pricing-fee">
												<h4 className={`text-primary ${checked ? "d-none" : "d-block"}`}>$5.00<span className="small fw-light pricing__price--small">/User/Month</span></h4>
												<h4 className={`text-primary ${checked ? "d-block" : "d-none"}`}>$8.00<span className="small fw-light pricing__price--small">/User/Month</span></h4>
											</div>
										</header>

										<div className="p-3">
											<p className="small bold pricing__price--small">Includes FREE, plus</p>
											<p>100 APPOINTMENTS</p>
											{/*<p>1 BOOKING INTERFACE</p>*/}
											{/*<p>INTEGRATIONS</p>*/}
											<p>ADDITIONAL APPOINTMENTS</p> {/*recurring*/}
											<p>ADDITIONAL USERS</p>
											<p>API ACCESS</p>
											<p>EMAIL SUPPORT</p>
											<div className="d-grid mt-5">
												<a className="btn btn-warning btn-pill" href="https://app.botscheduling.com/create?product=prod_M2D9oTSyMDWzgJ" target="_blank">
													GET STARTED
												</a>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="col-12 col-md-6 col-lg-3" data-aos="fade-up">
								<div className="px-xl-2">
									<div className="pricing-table-two shadow-sm rounded-3 mb-5">
										{/*<div className="ribbon ribbon-md ribbon-end">*/}
										{/*	<span className="bg-warning text-dark">Popular</span>*/}
										{/*</div>*/}
										<div className="p-3">
											<h3 className="fw-bold h4">Advanced</h3>
											<p className="sub-head text-muted">Small Business</p>
											<div className="line-after bg-gradient-primary mb-4" />
											<div className="pricing-fee">
												<h4 className={`text-primary ${checked ? "d-none" : "d-block"}`}>$10.00<span className="small fw-light pricing__price--small">/User/Month</span></h4>
												<h4 className={`text-primary ${checked ? "d-block" : "d-none"}`}>$14.00<span className="small fw-light pricing__price--small">/User/Month</span></h4>
											</div>
										</div>

										<div className="p-3">
											<p className="small bold pricing__price--small">Includes STARTER, plus</p>
											<p>1000 APPOINTMENTS</p>
											{/*<p>5 BOOKING INTERFACES</p>*/}
											<p>LOCATIONS</p>
											<p>CUSTOM PERMISSIONS</p>
											<p>PRIORITY SUPPORT</p>
											<div className="d-grid mt-5"><button className="btn btn-secondary btn-pill btn-block" disabled>COMING SOON</button></div>
										</div>
									</div>
								</div>
							</div>

							<div className="col-12 col-md-6 col-lg-3" data-aos="fade-up">
								<div className="px-xl-2">
									<div className="pricing-table-two shadow-sm rounded-3 mb-5">
										<header className="p-3">
											<h3 className=" fw-bold h4">Professional</h3>
											<p className="sub-head text-muted">Large Business</p>
											<div className="line-after bg-gradient-primary mb-4" />
											<div className="pricing-fee">
												<h4 className={`text-primary ${checked ? "d-none" : "d-block"}`}>$18.00<span className="small fw-light pricing__price--small">/User/Month</span></h4>
												<h4 className={`text-primary ${checked ? "d-block" : "d-none"}`}>$20.00<span className="small fw-light pricing__price--small">/User/Month</span></h4>
											</div>
										</header>

										<div className="p-3">
											<p className="small bold pricing__price--small">Includes ADVANCED, plus</p>
											<p>5000 APPOINTMENTS</p>
											{/*<p>BOOKING INTERFACES</p>*/}
											<p>SINGLE SIGN ON</p>
											<p>IP RESTRICTIONS</p>
											<p>HIGHEST PRIORITY</p>
											<div className="d-grid mt-5"><button className="btn btn-secondary btn-pill btn-block" disabled>COMING SOON</button></div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div id="reviews" className="section py-6 py-md-7 bg-white">
					<div className="container">
						{/*<header className="text-center mx-auto mb-5">*/}
						{/*	<h2 className="h3 fw-bold text-white">Compare Plans</h2>*/}
						{/*	<hr className="divider my-4 mx-auto bg-white border-white" />*/}
						{/*	/!*<p className="lead text-muted"></p>*!/*/}
						{/*</header>*/}

						<div className="card blog-one border-0 mb-4 mb-lg-0 rounded-3 overflow-hidden shadow-sm">
							<div className="card-body">
								<table className="table table-bordered">
									<thead className="alert alert-secondary">
										<tr>
											<th scope="col" className="py-4 text-center"></th>
											<th scope="col" className="py-4 text-center">Free</th>
											<th scope="col" className="py-4 text-center">Starter</th>
											<th scope="col" className="py-4 text-center">Advanced</th>
											<th scope="col" className="py-4 text-center">Professional</th>
										</tr>
									</thead>
									<tbody>
									<tr>
										<td scope="row" className="py-3">Appointments</td>
										<td className="py-3 text-center">100</td>
										<td className="py-3 text-center">100</td>
										<td className="py-3 text-center">1000</td>
										<td className="py-3 text-center">5000</td>
									</tr>
									<tr>
										<td scope="row" className="py-3">Additional Appointments<br />(per appointment)</td>
										<td className="py-3 text-center"></td>
										<td className="py-3 text-center">$.10</td>
										<td className="py-3 text-center">$.10</td>
										<td className="py-3 text-center">$.05</td>
									</tr>
									<tr>
										<td scope="row" className="py-3">Additional Users</td>
										<td className="py-3 text-center"></td>
										<td className="py-3 text-center"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 512 512"><polyline points="416 128 192 384 96 288" style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}}/></svg></td>
										<td className="py-3 text-center"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 512 512"><polyline points="416 128 192 384 96 288" style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}}/></svg></td>
										<td className="py-3 text-center"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 512 512"><polyline points="416 128 192 384 96 288" style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}}/></svg></td>
									</tr>
									<tr className="alert alert-secondary">
										<th scope="col" colSpan={5}>Workspace</th>
									</tr>
									<tr>
										<td scope="row" className="py-3">Schedules</td>
										<td className="py-3 text-center"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 512 512"><polyline points="416 128 192 384 96 288" style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}}/></svg></td>
										<td className="py-3 text-center"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 512 512"><polyline points="416 128 192 384 96 288" style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}}/></svg></td>
										<td className="py-3 text-center"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 512 512"><polyline points="416 128 192 384 96 288" style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}}/></svg></td>
										<td className="py-3 text-center"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 512 512"><polyline points="416 128 192 384 96 288" style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}}/></svg></td>
									</tr>
									<tr>
										<td scope="row" className="py-3">Multiple Locations</td>
										<td className="py-3 text-center"></td>
										<td className="py-3 text-center"></td>
										<td className="py-3 text-center"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 512 512"><polyline points="416 128 192 384 96 288" style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}}/></svg></td>
										<td className="py-3 text-center"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 512 512"><polyline points="416 128 192 384 96 288" style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}}/></svg></td>
									</tr>
									<tr>
										<td scope="row" className="py-3">Resources</td>
										<td className="py-3 text-center"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 512 512"><polyline points="416 128 192 384 96 288" style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}}/></svg></td>
										<td className="py-3 text-center"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 512 512"><polyline points="416 128 192 384 96 288" style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}}/></svg></td>
										<td className="py-3 text-center"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 512 512"><polyline points="416 128 192 384 96 288" style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}}/></svg></td>
										<td className="py-3 text-center"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 512 512"><polyline points="416 128 192 384 96 288" style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}}/></svg></td>
									</tr>
									<tr>
										<td scope="row" className="py-3">Tags</td>
										<td className="py-3 text-center"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 512 512"><polyline points="416 128 192 384 96 288" style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}}/></svg></td>
										<td className="py-3 text-center"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 512 512"><polyline points="416 128 192 384 96 288" style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}}/></svg></td>
										<td className="py-3 text-center"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 512 512"><polyline points="416 128 192 384 96 288" style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}}/></svg></td>
										<td className="py-3 text-center"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 512 512"><polyline points="416 128 192 384 96 288" style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}}/></svg></td>
									</tr>
									<tr>
										<td scope="row" className="py-3">Groups</td>
										<td className="py-3 text-center"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 512 512"><polyline points="416 128 192 384 96 288" style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}}/></svg></td>
										<td className="py-3 text-center"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 512 512"><polyline points="416 128 192 384 96 288" style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}}/></svg></td>
										<td className="py-3 text-center"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 512 512"><polyline points="416 128 192 384 96 288" style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}}/></svg></td>
										<td className="py-3 text-center"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 512 512"><polyline points="416 128 192 384 96 288" style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}}/></svg></td>
									</tr>
									<tr>
										<td scope="row" className="py-3">Reporting</td>
										<td className="py-3 text-center"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 512 512"><polyline points="416 128 192 384 96 288" style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}}/></svg></td>
										<td className="py-3 text-center"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 512 512"><polyline points="416 128 192 384 96 288" style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}}/></svg></td>
										<td className="py-3 text-center"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 512 512"><polyline points="416 128 192 384 96 288" style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}}/></svg></td>
										<td className="py-3 text-center"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 512 512"><polyline points="416 128 192 384 96 288" style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}}/></svg></td>
									</tr>
									<tr className="alert alert-secondary">
										<th scope="col" colSpan={5}>Appointments</th>
									</tr>
									<tr>
										<td scope="row" className="py-3">Custom Fields</td>
										<td className="py-3 text-center"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 512 512"><polyline points="416 128 192 384 96 288" style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}}/></svg></td>
										<td className="py-3 text-center"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 512 512"><polyline points="416 128 192 384 96 288" style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}}/></svg></td>
										<td className="py-3 text-center"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 512 512"><polyline points="416 128 192 384 96 288" style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}}/></svg></td>
										<td className="py-3 text-center"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 512 512"><polyline points="416 128 192 384 96 288" style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}}/></svg></td>
									</tr>
									<tr>
										<td scope="row" className="py-3">Email Notifications</td>
										<td className="py-3 text-center"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 512 512"><polyline points="416 128 192 384 96 288" style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}}/></svg></td>
										<td className="py-3 text-center"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 512 512"><polyline points="416 128 192 384 96 288" style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}}/></svg></td>
										<td className="py-3 text-center"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 512 512"><polyline points="416 128 192 384 96 288" style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}}/></svg></td>
										<td className="py-3 text-center"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 512 512"><polyline points="416 128 192 384 96 288" style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}}/></svg></td>
									</tr>
									<tr>
										<td scope="row" className="py-3">SMS</td>
										<td className="py-3 text-center"></td>
										<td className="py-3 text-center"></td>
										<td className="py-3 text-center"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 512 512"><polyline points="416 128 192 384 96 288" style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}}/></svg></td>
										<td className="py-3 text-center"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 512 512"><polyline points="416 128 192 384 96 288" style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}}/></svg></td>
									</tr>
									<tr>
										<td scope="row" className="py-3">Recurring</td>
										<td className="py-3 text-center"></td>
										<td className="py-3 text-center"></td>
										<td className="py-3 text-center"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 512 512"><polyline points="416 128 192 384 96 288" style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}}/></svg></td>
										<td className="py-3 text-center"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 512 512"><polyline points="416 128 192 384 96 288" style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}}/></svg></td>
									</tr>
									<tr>
										<td scope="row" className="py-3">Files</td>
										<td className="py-3 text-center"></td>
										<td className="py-3 text-center"></td>
										<td className="py-3 text-center"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 512 512"><polyline points="416 128 192 384 96 288" style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}}/></svg></td>
										<td className="py-3 text-center"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 512 512"><polyline points="416 128 192 384 96 288" style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}}/></svg></td>
									</tr>
									<tr>
										<td scope="row" className="py-3">History</td>
										<td className="py-3 text-center">7 days</td>
										<td className="py-3 text-center">30 days</td>
										<td className="py-3 text-center"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 512 512"><polyline points="416 128 192 384 96 288" style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}}/></svg></td>
										<td className="py-3 text-center"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 512 512"><polyline points="416 128 192 384 96 288" style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}}/></svg></td>
									</tr>
									<tr className="alert alert-secondary">
										<th scope="col" colSpan={5}>Customer Booking</th>
									</tr>
									<tr>
										<td scope="row" className="py-3">Interface(s)</td>
										<td className="py-3 text-center">1</td>
										<td className="py-3 text-center">1</td>
										<td className="py-3 text-center">5</td>
										<td className="py-3 text-center"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 512 512"><path d="M256,256s-48-96-126-96c-54.12,0-98,43-98,96s43.88,96,98,96c37.51,0,71-22.41,94-48" style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeMiterlimit:"10",strokeWidth:"32px"}}/><path d="M256,256s48,96,126,96c54.12,0,98-43,98-96s-43.88-96-98-96c-37.51,0-71,22.41-94,48" style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeMiterlimit:"10",strokeWidth:"32px"}}/></svg></td>
									</tr>
									<tr className="alert alert-secondary">
										<th scope="col" colSpan={5}>Integrations</th>
									</tr>
									<tr>
										<td scope="row" className="py-3">Stripe</td>
										<td className="py-3 text-center"></td>
										<td className="py-3 text-center"></td>
										<td className="py-3 text-center"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 512 512"><polyline points="416 128 192 384 96 288" style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}}/></svg></td>
										<td className="py-3 text-center"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 512 512"><polyline points="416 128 192 384 96 288" style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}}/></svg></td>
									</tr>
									<tr>
										<td scope="row" className="py-3">Google Calendar</td>
										<td className="py-3 text-center"></td>
										<td className="py-3 text-center"></td>
										<td className="py-3 text-center"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 512 512"><polyline points="416 128 192 384 96 288" style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}}/></svg></td>
										<td className="py-3 text-center"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 512 512"><polyline points="416 128 192 384 96 288" style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}}/></svg></td>
									</tr>
									<tr>
										<td scope="row" className="py-3">Outlook 365</td>
										<td className="py-3 text-center"></td>
										<td className="py-3 text-center"></td>
										<td className="py-3 text-center"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 512 512"><polyline points="416 128 192 384 96 288" style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}}/></svg></td>
										<td className="py-3 text-center"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 512 512"><polyline points="416 128 192 384 96 288" style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}}/></svg></td>
									</tr>
									<tr>
										<td scope="row" className="py-3">API</td>
										<td className="py-3 text-center"></td>
										<td className="py-3 text-center"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 512 512"><polyline points="416 128 192 384 96 288" style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}}/></svg></td>
										<td className="py-3 text-center"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 512 512"><polyline points="416 128 192 384 96 288" style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}}/></svg></td>
										<td className="py-3 text-center"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 512 512"><polyline points="416 128 192 384 96 288" style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}}/></svg></td>
									</tr>
									<tr className="alert alert-secondary">
										<th scope="col" colSpan={5}>Security</th>
									</tr>
									<tr>
										<td scope="row" className="py-3">User Permissions</td>
										<td className="py-3 text-center"></td>
										<td className="py-3 text-center">Basic</td>
										<td className="py-3 text-center">Custom</td>
										<td className="py-3 text-center">Custom</td>
									</tr>
									<tr>
										<td scope="row" className="py-3">SSL</td>
										<td className="py-3 text-center"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 512 512"><polyline points="416 128 192 384 96 288" style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}}/></svg></td>
										<td className="py-3 text-center"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 512 512"><polyline points="416 128 192 384 96 288" style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}}/></svg></td>
										<td className="py-3 text-center"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 512 512"><polyline points="416 128 192 384 96 288" style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}}/></svg></td>
										<td className="py-3 text-center"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 512 512"><polyline points="416 128 192 384 96 288" style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}}/></svg></td>
									</tr>
									<tr>
										<td scope="row" className="py-3">2FA</td>
										<td className="py-3 text-center"></td>
										<td className="py-3 text-center"></td>
										<td className="py-3 text-center"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 512 512"><polyline points="416 128 192 384 96 288" style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}}/></svg></td>
										<td className="py-3 text-center"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 512 512"><polyline points="416 128 192 384 96 288" style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}}/></svg></td>
									</tr>
									<tr>
										<td scope="row" className="py-3">SSO</td>
										<td className="py-3 text-center"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 512 512"><polyline points="416 128 192 384 96 288" style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}}/></svg></td>
										<td className="py-3 text-center"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 512 512"><polyline points="416 128 192 384 96 288" style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}}/></svg></td>
										<td className="py-3 text-center"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 512 512"><polyline points="416 128 192 384 96 288" style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}}/></svg></td>
										<td className="py-3 text-center"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 512 512"><polyline points="416 128 192 384 96 288" style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}}/></svg></td>
									</tr>
									<tr>
										<td scope="row" className="py-3">Audit Logs</td>
										<td className="py-3 text-center"></td>
										<td className="py-3 text-center"></td>
										<td className="py-3 text-center"></td>
										<td className="py-3 text-center"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 512 512"><polyline points="416 128 192 384 96 288" style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}}/></svg></td>
									</tr>
									<tr>
										<td scope="row" className="py-3">IP Restrictions</td>
										<td className="py-3 text-center"></td>
										<td className="py-3 text-center"></td>
										<td className="py-3 text-center"></td>
										<td className="py-3 text-center"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 512 512"><polyline points="416 128 192 384 96 288" style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}}/></svg></td>
									</tr>
									<tr className="alert alert-secondary">
										<th scope="col" colSpan={5}>Support</th>
									</tr>
									{/*<tr>*/}
									{/*	<th scope="row" className="py-3">Phone</th>*/}
									{/*	<td className="py-3"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 512 512"><polyline points="416 128 192 384 96 288" style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}}/></svg></td>*/}
									{/*	<td className="py-3"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 512 512"><polyline points="416 128 192 384 96 288" style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}}/></svg></td>*/}
									{/*	<td className="py-3"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 512 512"><polyline points="416 128 192 384 96 288" style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}}/></svg></td>*/}
									{/*	<td className="py-3"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 512 512"><polyline points="416 128 192 384 96 288" style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}}/></svg></td>*/}
									{/*</tr>*/}
									<tr>
										<td scope="row" className="py-3">Email Priority</td>
										<td className="py-3 text-center">Normal</td>
										<td className="py-3 text-center">Normal</td>
										<td className="py-3 text-center">High</td>
										<td className="py-3 text-center">Highest</td>
									</tr>
									<tr>
										<td scope="row" className="py-3">Knowledge Base</td>
										<td className="py-3 text-center"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 512 512"><polyline points="416 128 192 384 96 288" style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}}/></svg></td>
										<td className="py-3 text-center"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 512 512"><polyline points="416 128 192 384 96 288" style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}}/></svg></td>
										<td className="py-3 text-center"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 512 512"><polyline points="416 128 192 384 96 288" style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}}/></svg></td>
										<td className="py-3 text-center"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="currentColor" viewBox="0 0 512 512"><polyline points="416 128 192 384 96 288" style={{fill:"none",stroke:"currentColor",strokeLinecap:"round",strokeLinejoin:"round",strokeWidth:"32px"}}/></svg></td>
									</tr>
									<tr>
										<td scope="row" className="py-3"></td>
										<td className="py-2 px-0 text-center"><a href="https://app.botscheduling.com/create" target="_blank" className="btn btn-sm btn-pill btn-secondary btn-block">GET STARTED</a></td>
										<td className="py-2 px-0 text-center"><a href="https://app.botscheduling.com/create?product=prod_M2D9oTSyMDWzgJ" target="_blank" className="btn btn-sm btn-pill btn-warning btn-block">GET STARTED</a></td>
										<td className="py-2 px-0 text-center"><button className="btn btn-sm btn-pill btn-secondary btn-block" disabled>COMING SOON</button></td>
										<td className="py-2 px-0 text-center"><button className="btn btn-sm btn-pill btn-secondary btn-block" disabled>COMING SOON</button></td>
									</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>

				<div className="bg-secondary">
					<svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none"
						 fill="#ffffff"
						 width="200%"
						 height="50px"
						 transform="rotateY(180deg)"
					>
						<path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill" />
					</svg>
				</div>
			</main>

			<Footer />
		</Fragment>
	)
}

export default Pricing;