import React, { Fragment } from 'react';

const TradeBusiness = () => {
	return (
		<Fragment>
			<div id="about" className="section py-6 py-md-7 bg-white">
				<div className="container">
					<div className="row justify-content-between">
						<div className="col-lg-12" data-aos="flip-up">
							<div className="py-0">
								<h1 className="fw-bold mb-3">Trade Business</h1>
								<p>BotScheduling redefines service management for trade businesses, offering a fresh approach to how appointments and tasks are efficiently organized and executed. Whether it involves scheduling landscaping projects, coordinating handyman or repair services, arranging painting jobs, or booking plumbing appointments, BotScheduling smoothly streamlines day-to-day operations and enhances customer interactions.</p>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div id="about" className="section py-6 py-md-7 bg-light">
				<div className="container">
					<div className="row justify-content-between">
						<div className="col-lg-12" data-aos="flip-up">
							<div className="py-0">
								<h2 className="fw-bold mb-3">Landscaping</h2>
								<ul>
									<li>Effortlessly manage landscaping appointments for smoother operations.</li>
									<li>Reduce scheduling conflicts, ensuring efficient service delivery.</li>
									<li>Engage clients with convenient online booking options.</li>
									<li>Allocate resources effectively, optimizing team productivity.</li>
									<li>Devote more time to providing top-quality landscaping services</li>
								</ul>
							</div>
						</div>
					</div>
				</div>

				<div className="container mt-4">
					<div className="row justify-content-between">
						<div className="col-lg-12" data-aos="flip-up">
							<div className="py-0">
								<h2 className="fw-bold mb-3">Handymen or Repair Services</h2>
								<ul>
									<li>Simplify appointment scheduling for smoother repair service coordination.</li>
									<li>Prevent scheduling conflicts, ensuring timely and effective repairs.</li>
									<li>Engage clients with easy online booking for improved satisfaction.</li>
									<li>Allocate resources efficiently for better productivity among your repair teams.</li>
									<li>Focus on delivering high-quality repair services with more time on your hands.</li>
								</ul>
							</div>
						</div>
					</div>
				</div>

				<div className="container mt-4">
					<div className="row justify-content-between">
						<div className="col-lg-12" data-aos="flip-up">
							<div className="py-0">
								<h2 className="fw-bold mb-3">Painters, Plumbers, etc.</h2>
								<ul>
									<li>Simplify appointment booking for smooth service coordination.</li>
									<li>Avoid scheduling conflicts, ensuring punctual and efficient work.</li>
									<li>Engage clients with straightforward online booking for improved satisfaction.</li>
									<li>Optimize resource allocation, maximizing work efficiency.</li>
									<li>Focus on delivering high-quality services with less hassle.</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	)
}

export default TradeBusiness;