import React, { Fragment } from 'react';
import { Link } from "react-router-dom";
import Header from "../docs/fragments/Header";
import Footer from "../fragments/Footer";

export const Support = () => {
	return (
		<Fragment>
			<link rel="stylesheet" type="text/css" href="/src/css/docs.css" />

			<Header forceLightMenu={true} />
			<div id="content" className="container">
				<div className="row">
					<div className="col-12 pt-4 pt-lg-0 d-lg-none">
						{/* left nav */}
						<button type="button" className="border" data-bs-toggle="collapse" data-bs-target="#leftnav" aria-controls="leftnav" aria-expanded="false" aria-label="Toggle navigation">
							<svg xmlns="http://www.w3.org/2000/svg" className="text-primary me-2" width="32px" height="32px" fill="currentColor" viewBox="0 0 512 512"><line x1={80} y1={160} x2={432} y2={160} style={{fill: 'none', stroke: 'currentColor', strokeLinecap: 'round', strokeMiterlimit: 10, strokeWidth: '32px'}} /><line x1={80} y1={256} x2={432} y2={256} style={{fill: 'none', stroke: 'currentColor', strokeLinecap: 'round', strokeMiterlimit: 10, strokeWidth: '32px'}} /><line x1={80} y1={352} x2={432} y2={352} style={{fill: 'none', stroke: 'currentColor', strokeLinecap: 'round', strokeMiterlimit: 10, strokeWidth: '32px'}} /></svg>
							Left Sidebar Menu
						</button>
					</div>
					{/*left sidebar start*/}
					<div className="col-12 col-lg-3 col-xl-3 bn-sidebar">
						<nav className="collapse d-lg-block ps-lg-3 py-3" id="leftnav">
							<div className="bn-toc-item">
								<a className="bn-toc-link" href="#" data-bs-toggle="collapse" data-bs-target={`#collapse1`} aria-expanded="false" aria-controls={`collapse1`}>
									Support
								</a>
								<ul className="nav milyader collapse active" id={`collapse1`} data-bs-parent="#leftnav">
									<li><Link to={`/docs/api`}>Contact</Link></li>
								</ul>
							</div>
						</nav>
					</div>
					{/*Left sidebar end*/}
					{/*Content start*/}
					<main className="col-12 col-lg-9 col-xl-9 px-md-3 ps-lg-5 pe-lg-4 bn-content pb-5">
						<p>To contact support, please go here:</p>
						<ul>
							<li>Go to <Link to="/contact">Contact Us</Link></li>
							<li>Fill out the form</li>
							<li>Click "Submit"</li>
						</ul>

						<p>We will respond to your request as soon as possible.</p>

						<p>If you need to contact sales, please go here: <Link to="/contact">Contact Us</Link></p>
					</main>
					{/*Content end*/}
				</div>
			</div>
			<Footer />
		</Fragment>
	)
}

export default Support;