import React, { Fragment } from 'react';

const General = () => {
	return (
		<Fragment>
			{/* =========={ About }==========  */}
			<div id="about" className="section py-6 py-md-7 bg-white">
				<div className="container">
					{/* row */}
					<div className="row justify-content-between">
						<div className="col-lg-12" data-aos="flip-up">
							<div className="py-0">
								<h1 className="fw-bold mb-3">Your Scheduling Solution</h1>
								<p>BotScheduling provides significant advantages for companies seeking to optimize their scheduling processes. This software simplifies appointment management, customizes scheduling rules, and minimizes no-shows through automated reminders and real-time updates, ultimately improving operational efficiency and customer service for any organization.</p>
							</div>
						</div>
					</div>
				</div>
			</div>{/* End About */}

			{/* =========={ About }==========  */}
			<div id="about" className="section py-6 py-md-7 bg-light">
				<div className="container mt-4">
					{/* row */}
					<div className="row justify-content-between">
						<div className="col-lg-12" data-aos="flip-up">
							<div className="py-0">
								<p>Here are several ways in which BotScheduling can have a significant impact your company's scheduling needs:</p>
								<ul>
									<li><strong>Efficiency:</strong> Streamlines the scheduling process, reducing administrative workload.</li>
									<li><strong>Customization:</strong> Allows for tailored scheduling rules and preferences.</li>
									<li><strong>Reduced No-Shows:</strong> Automated reminders and real-time updates minimize missed appointments.</li>
									<li><strong>Improved Customer Service:</strong> Enhances overall customer experience by providing easy appointment booking and management.</li>
									<li><strong>Resource Optimization:</strong> Efficiently allocates resources like staff and facilities.</li>
									<li><strong>Increased Productivity:</strong> Frees up staff time for more critical tasks.</li>
									<li><strong>Accurate Reporting:</strong> Provides valuable data for performance analysis and decision-making.</li>
									<li><strong>Saves Time and Costs:</strong> Reduces manual scheduling efforts and decreases the likelihood of scheduling conflicts.</li>
									<li><strong>Scalability:</strong> Adapts to the growing scheduling needs of a company.</li>
									<li><strong>Enhanced Record-Keeping:</strong> Maintains a historical record of appointments and interactions for reference and analysis.</li>
								</ul>
								<p>These benefits collectively contribute to a more streamlined and effective scheduling process for companies, leading to improved productivity and customer satisfaction.</p>
							</div>
						</div>
					</div>
				</div>
			</div>{/* End About */}
		</Fragment>
	)
}

export default General;