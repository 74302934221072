import React, { Fragment } from 'react';
import { Link } from "react-router-dom";
import { ReactComponent as ReactLogoLight } from '../../../logo/BotSchedulingLogoWhite.svg';

const Footer = () => {
	const date: Date = new Date();
	const year: number = date.getFullYear();

	return (
		<Fragment>
			<footer className="bg-secondary">
				<div id="footer-content" className="footer-dark pt-6 pb-5">
					<div className="container">
						<div className="row">
							<div className="col-lg-4">
								<div className="d-flex align-item-center mb-2">
									<ReactLogoLight width="240px" height="48px" />
								</div>
								<p>An intelligent online scheduling solution</p>
								<ul className="footer-links list-unstyled social-icon mb-5 mb-lg-0">
									<li className="my-2 me-3">
										<a target="_blank" rel="noreferrer" href="https://rumble.com" title="Rumble">
											<svg version="1.2" xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 1441 1583">
												<title>rumble-full-logo-v4-svg</title>
												<path fill="currentColor" id="Layer" fillRule="evenodd" className="s0" d="m1339.3 537.2c32.1 35.2 57.5 75.9 74.8 120.3 17.2 44.4 26.1 91.5 26.2 139.2 0.1 47.6-8.6 94.8-25.7 139.2-17.1 44.4-42.3 85.3-74.3 120.6-57.2 63.1-119 121.9-184.9 175.9-65.9 54-135.7 103-208.9 146.7-73.1 43.6-149.4 81.8-228.2 114.2-78.8 32.4-159.9 59-242.6 79.4-42.2 10.6-86.1 12.9-129.1 6.8-43.1-6.1-84.6-20.4-122.2-42.3-37.7-21.8-70.7-50.7-97.4-85-26.6-34.4-46.4-73.6-58.2-115.4-100.4-343-85.6-730.8 11.2-1075.3 51-180.9 221.3-294.5 396.7-252.7 324.8 77.4 629.6 276.7 862.6 528.4zm-457.3 356.2c61.2-48.4 61.2-142.7 0-192.6q-32.6-27.1-66.8-52.1-34.2-25.1-69.9-48-35.6-22.9-72.6-43.7-37-20.7-75.2-39.2c-70.3-33.6-148.8 13.3-160.1 93.3-17.3 122.3-20.4 245.6-9.1 362.8 7.6 81.1 85.1 129.5 156.5 98.4q39.9-17.2 78.6-37 38.7-19.8 76-42.2 37.3-22.4 73-47.2 35.7-24.8 69.6-52z" />
											</svg>
										</a>
									</li>
									<li className="my-2 me-3">
										<a target="_blank" rel="noreferrer" href="https://x.com/BotScheduling" title="X">
											<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" version="1.1" viewBox="0 0 300 300">
												<path fill="currentColor" d="M178.57 127.15 290.27 0h-26.46l-97.03 110.38L89.34 0H0l117.13 166.93L0 300.25h26.46l102.4-116.59 81.8 116.59h89.34M36.01 19.54H76.66l187.13 262.13h-40.66" />
											</svg>
										</a>
									</li>
								</ul>
							</div>
							<div className="col-lg-8">
								<div className="row">
									<div className="col-md-6 col-lg-4 mb-5 mb-lg-0">
										<h4 className="h6 text-light text-uppercase">Resources</h4>
										<ul className="list-unstyled footer-links mb-0">
											<li className="py-2"><Link to="/product">Product</Link></li>
											<li className="py-2"><Link to="/features">Features</Link></li>
											<li className="py-2"><Link to="/pricing">Pricing</Link></li>
											<li className="py-2"><Link to="/docs">Documentation</Link></li>
											<li className="py-2"><Link to="/privacy">Privacy Policy</Link></li>
											<li className="py-2"><Link to="/terms">Terms Of Use</Link></li>
										</ul>
									</div>
									<div className="col-md-6 col-lg-4 mb-5 mb-md-0">
										<h4 className="h6 text-light text-uppercase">Industries</h4>
										<ul className="list-unstyled footer-links mb-0">
											<li className="py-2"><Link to="/industries/health-and-beauty">Health, Mind and Beauty</Link></li>
											<li className="py-2"><Link to="/industries/professional-services">Professional Services</Link></li>
											<li className="py-2"><Link to="/industries/education">Education</Link></li>
											<li className="py-2"><Link to="/industries/trade-business">Trade Business</Link></li>
										</ul>
									</div>
									<div className="col-md-6 col-lg-4 mb-md-0">
										<h4 className="h6 text-light text-uppercase">Connect</h4>
										<ul className="list-unstyled footer-links mb-0">
											{/*<li className="py-2">602-644-1425</li>*/}
											<li className="py-2"><Link to="/contact">Contact Us</Link></li>
										</ul>
										<h4 className="h6 text-light text-uppercase mt-3">Developers</h4>
										<ul className="list-unstyled footer-links mb-0">
											<li className="py-2"><Link to="/docs/api">API Documentation</Link></li>
											<li className="py-2"><Link to="/docs/support">Support</Link></li>
											<li className="py-2"><a href="https://botscheduling.statuspage.io" target="_blank">System Status</a></li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="footer-dark">
					<div className="container py-4 border-top border-smooth">
						<div className="row">
							<div className="col-12 col-md text-center">
								<p className="d-block my-3">&copy; BotScheduling {year}. All rights reserved.</p>
							</div>
						</div>
					</div>
				</div>
			</footer>
		</Fragment>
	);
}

export default Footer;