import React, { Fragment, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {ReactComponent as ReactLogo} from '../../logo/BotSchedulingIconOriginal.svg';

const Auth = () => {
	const [ credentials, setCredentials ] = useState({
		'password': ''
	});

	const onChange = (e: React.ChangeEvent<HTMLInputElement>) => setCredentials({ ...credentials, [e.target.name]: e.target.value });

	const navigate = useNavigate();

	const handleKeyPress = (event: any) => {
		if (event.key === 'Enter') {
			onSubmit();
		}
	};

	const onSubmit = () => {
		if (credentials.password === 'letmein') {
			localStorage.setItem('token', credentials.password);
			navigate('/');
		}
	}

	return (
		<Fragment>
			<main id="content">
				<div id="features" className="section py-6 py-md-7 bg-light" style={{minHeight: "100vh"}}>
					<div className="container">
						{/*<div className="row text-center">*/}
						{/*	<div className="col-12">*/}
						{/*		<div className="mb-5">*/}
						{/*			<h2 className="h2 fw-bold">Features Mean Everything</h2>*/}
						{/*			/!*<hr class="divider my-4 mx-auto bg-warning border-warning">*!/*/}
						{/*			<p className="lead text-muted">Discover our wide-range features to help your*/}
						{/*				business grow.</p>*/}
						{/*		</div>*/}
						{/*	</div>*/}
						{/*</div>*/}

						<div className="row">
							{/*<div className="d-none d-lg-block d-xl-block col-lg-4 text-center order-lg-2" data-aos="fade-up">*/}
							{/*	<div className="px-6 px-md-9 px-lg-5 px-xl-6 mb-5 mb-lg-0">*/}
							{/*		<img src="/src/logo/Feature-Bot1.png" className="img-fluid" alt="landing page" />*/}
							{/*	</div>*/}
							{/*	<div className="px-6 px-md-9 px-lg-6 px-xl-7 mb-5 mb-lg-0 mt-5">*/}
							{/*		<img src="/src/logo/Feature-Bot2.png" className="img-fluid animated-up-down" alt="landing page" />*/}
							{/*	</div>*/}
							{/*</div>*/}
							<div className="row justify-content-center">
								<div className="col-lg-7" data-aos="fade-up">
									<div className="py-0 py-lg-5 text-center">
										<div className="mb-5">
											<ReactLogo width={"25%"} height={"25%"} />
										</div>
										<h1 className="display-4 fw-bold">Coming Soon</h1>
										<p className="m-3">We are committed to delivering a top-notch experience and are in the final stages of development. If you have been granted early access with a password, please feel free to provide it below.</p>
										<div className="input-group input-group-newsletter mb-3">
											<input type="password" name="password" className="form-control" onKeyDown={(e) => handleKeyPress(e)} placeholder="Enter password..." aria-label="Enter password..." onChange={e => onChange(e)} />
											<button className="btn btn-warning" type="button" onClick={onSubmit}>Go</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				{/*<div id="hero" className="section">*/}
				{/*	<div className="z-index-1" style={{minHeight: "100vh"}}>*/}
				{/*		<div className="overlay bg-primary opacity-70 z-index-n1" />*/}

				{/*		<div className="container pt-1 pb-1">*/}
				{/*			<div className="row justify-content-center">*/}
				{/*				<div className="col-lg-7" data-aos="fade-up">*/}
				{/*					<div className="py-0 py-lg-5 text-center">*/}
				{/*						<div className="mb-5">*/}
				{/*							<svg xmlns="http://www.w3.org/2000/svg" width="25%" viewBox="0 0 73 57"><defs><style dangerouslySetInnerHTML={{__html: ".cls-1{fill:#d15f4f;}.cls-2{fill:#36a3c0;}.cls-3{fill:#60af9e;}.cls-4{fill:#d67e49;}.cls-5{fill:#ecca65;}" }} /></defs><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><rect className="cls-1" x="59.02" y="41.91" width="13.26" height="14.41" rx="0.99" /><rect className="cls-2" x="59.03" y="13.52" width="13.25" height="23.7" rx="0.99" /><rect className="cls-3" x="39.4" width="13.26" height="47.94" rx="0.99" /><rect className="cls-2" x="19.65" y="26.16" width="13.25" height="30.15" rx="0.99" /><rect className="cls-4" y="31.92" width="13.24" height="24.29" rx="0.99" /><rect className="cls-5" x="19.59" y="8.05" width="13.26" height="13.37" rx="0.99" /></g></g></svg>*/}
				{/*						</div>*/}
				{/*						<h1 className="display-4 fw-bold text-white mb-3">Coming Soon</h1>*/}
				{/*						<p className="lead text-light mb-5">We are working hard to complete the*/}
				{/*							development of this site. If you have a password, please login below.</p>*/}
				{/*						<div className="input-group input-group-newsletter mb-3">*/}
				{/*							<input type="password" name="password" className="form-control" onKeyDown={(e) => handleKeyPress(e)} placeholder="Enter password..." aria-label="Enter password..." onChange={e => onChange(e)} />*/}
				{/*							<button className="btn btn-warning" type="button" onClick={onSubmit}>Go</button>*/}
				{/*						</div>*/}
				{/*					</div>*/}
				{/*				</div>*/}
				{/*			</div>*/}
				{/*		</div>*/}
				{/*	</div>*/}
				{/*</div>*/}
			</main>
		</Fragment>
	)
}

export default Auth;