import React, { Fragment, useEffect } from 'react';
import Header from './fragments/Header'
import Footer from './fragments/Footer'
import api from "../../network/Api";

type YourItemType = {
    id: number;
    title: string;
    body: string;
    h2Tags: string[];
    // Add other properties here...
};

const Privacy = () => {
    useEffect(() => {
        loadArticle();
    }, []);

    const [article, setArticle] = React.useState<YourItemType>({
        id: 0,
        title: '',
        body: '',
        h2Tags: [],
    });

    const loadArticle = async () => {
        const res = await api.get(`/core/v2/knowledge-base/articles/privacy-policy`);

        // Create a temporary HTML element
        const tempElement = document.createElement('div');
        tempElement.innerHTML = res.data.body;

        // Find all <h2> elements within the temporary element
        const h2Elements = tempElement.querySelectorAll('h2');

        // Extract and log the text content of <h2> elements
        const h2TagValues = Array.from(h2Elements).map((element) => element.textContent);

        h2Elements.forEach((element) => {
            const textContent: string|null = element.textContent;
            if (textContent) {
                element.setAttribute('id', textContent.replace(/[^\w\s-]/g, '').toLowerCase().replace(/\s+/g, '-'));
            }
        });

        const article = res.data;
        article.body = tempElement.innerHTML;
        article.h2Tags = h2TagValues;

        setArticle(article);
    }

    const customStyles = `
        .main-content h1 {
            margin-top: 2rem;
        }
        
        .main-content h2 {
            font-size: 1.5rem;
            margin-top: 1.2rem;
        }
        
        .main-content h3 {
            font-size: 1.25rem;
            margin-top: 1.2rem;
        }
        
        .main-content ul {
          margin-top: 1.2rem;
        }
    `;

    return (
        <Fragment>
            <Header forceLightMenu={true} />
            <style>{customStyles}</style>
            <main id="content">
                {/* =========={ Terms }==========  */}
                <div id="about" className="section py-6 py-md-7 bg-white main-content">
                    <div className="container">
                        {/* row */}
                        <div className="row justify-content-between">
                            <div className="col-lg-12" data-aos="flip-up">
                                <div className="py-0">
                                    <div
                                        dangerouslySetInnerHTML={{__html: article.body}}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </Fragment>
    )
};

export default Privacy;