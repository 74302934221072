import React, { Fragment, useEffect } from 'react';
import { Link, useParams } from "react-router-dom";
import Header from "./fragments/Header";
import Footer from "../fragments/Footer";
import api from "../../../network/Api";

type YourItemType = {
    id: number;
    title: string;
    body: string;
    h2Tags: string[];
    // Add other properties here...
};

export const Docs = () => {
    const { title } = useParams();

    useEffect(() => {
        loadArticles();

        // Define the event listener function
        const handleHashChange = () => {
            // This function will be called when the hash in the URL changes
            // You can put your code here to handle the hash change event
            window.scrollTo(window.scrollX, window.scrollY - 90);
        };

        // Add the event listener when the component mounts
        window.addEventListener('hashchange', handleHashChange);

        // Clean up by removing the event listener when the component unmounts
        return () => {
            window.removeEventListener('hashchange', handleHashChange);
        };
    }, []);

    useEffect(() => {
        if (title) {
            loadArticle(title);
        }
    }, [title]);

    const [article, setArticle] = React.useState<YourItemType>({
        id: 0,
        title: '',
        body: '',
        h2Tags: [],
    });
    const [articles, setArticles] = React.useState<{ [key: string]: YourItemType[] }>({});

    const predeterminedFolderOrder = [
        'Getting Started',
        'Workspaces',
        'Users',
        'Customers',
        'Billing',
        'Services',
        'Locations',
        'Resources',
        'Permissions',
        'Ticket System',
        'News and Updates'
    ];

    const loadArticles = async () => {
        const res = await api.get(`/core/v2/knowledge-base/articles`);

        // Group the data as before
        const groupedData = res.data.reduce((result: { [x: string]: any[]; }, item: { folderName: any; }) => {
            const categoryId = item.folderName;

            if (!result[categoryId]) {
                result[categoryId] = [];
            }

            result[categoryId].push(item);

            return result;
        }, {});

        // Sort the categories based on the predetermined list
        const sortedCategories = Object.keys(groupedData).sort((a, b) => {
            const indexA = predeterminedFolderOrder.indexOf(a);
            const indexB = predeterminedFolderOrder.indexOf(b);

            // Handle categories not found in the predetermined list
            if (indexA === -1 && indexB === -1) {
                return 0; // Both categories not found, keep the order as is
            } else if (indexA === -1) {
                return 1; // Category A not found, so B comes before A
            } else if (indexB === -1) {
                return -1; // Category B not found, so A comes before B
            } else {
                return indexA - indexB; // Compare the positions in the list
            }
        });

        // Reconstruct the grouped data with sorted categories
        const sortedArticles = sortedCategories.reduce((result: { [x: string]: any[]; }, categoryId: string) => {
            result[categoryId] = groupedData[categoryId];
            return result;
        }, {});

        setArticles(sortedArticles);
    }

    const loadArticle = async (title: string) => {

        api.get(`/core/v2/knowledge-base/articles/${title}`,
            {
            validateStatus: function (status) {
                return status === 200
            }
        }).then(function (response) {
            // Create a temporary HTML element
            const tempElement = document.createElement('div');
            tempElement.innerHTML = response.data.body;

            // Find all <h2> elements within the temporary element
            const h2Elements = tempElement.querySelectorAll('h2');

            // Extract and log the text content of <h2> elements
            const h2TagValues = Array.from(h2Elements).map((element) => element.textContent);

            h2Elements.forEach((element) => {
                const textContent: string|null = element.textContent;
                if (textContent) {
                    element.setAttribute('id', textContent.replace(/[^\w\s-]/g, '').toLowerCase().replace(/\s+/g, '-').replace(/\?/g, ''));
                }
            });

            const article = response.data;
            article.body = tempElement.innerHTML;
            article.h2Tags = h2TagValues;

            setArticle(article);
        }).catch(function (error) {
            setArticle({
                id: 0,
                title: 'Not Found',
                body: '<h1>Not Found</h1>' +
                    '<p>This knowledgebase article is not found or was moved</p>',
                h2Tags: [],
            });
        });
    }

    const customStyles = `
        .main-content h1 {
            margin-top: 2rem;
        }
        
        .main-content h2 {
            font-size: 1.5rem;
            margin-top: 1.2rem;
        }
        
        .main-content h3 {
            font-size: 1.25rem;
            margin-top: 1.2rem;
        }
        
        .main-content h4 {
            font-size: 1.1rem;
            margin-top: 1.2rem;
        }
        
        .main-content ul {
          margin-top: 1.2rem;
        }
    `;

    const svgContent = `
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 394.46 449.99">
			<defs>
				<style>
					.cls1-1{fill:#d15f4f;stroke:none;opacity:0.10;}
					.cls1-2{fill:#ecca65;stroke:none;opacity:0.10;}
					.cls1-3{fill:#36a3c0;stroke:none;opacity:0.10;}
					.cls1-4{fill:#d15f4f;stroke:none;opacity:0.10;}
					.cls1-5{fill:#60af9e;stroke:none;opacity:0.10;}
				</style>
			</defs>
			<g id="Layer_6" data-name="Layer 6">
				<g id="Layer_3-6" data-name="Layer 3">
					<rect class="cls1-1" x="-10" y="-10" width="75" height="50" rx="6" transform="rotate(0 300 30)"/>
				</g>
				<g id="Layer_3-6" data-name="Layer 3">
					<rect class="cls1-2" x="75" y="-10" width="75" height="70" rx="6" transform="rotate(0 300 30)"/>
				</g>
				<g id="Layer_3-6" data-name="Layer 3">
					<rect class="cls1-3" x="165" y="-10" width="75" height="60" rx="6" transform="rotate(0 300 30)"/>
				</g>
				<g id="Layer_3-6" data-name="Layer 3">
					<rect class="cls1-4" x="250" y="-10" width="75" height="90" rx="6" transform="rotate(0 300 30)"/>
				</g>
				<g id="Layer_3-6" data-name="Layer 3">
					<rect class="cls1-5" x="335" y="-10" width="75" height="30" rx="6" transform="rotate(0 300 30)"/>
				</g>
			</g>
		</svg>
	`;

    const containerStyle = {
        backgroundImage: `url("data:image/svg+xml;utf8,${encodeURIComponent(svgContent)}")`,
        backgroundSize: 'cover', // Adjust background size as needed
        backgroundRepeat: 'no-repeat', // Prevent the background image from repeating
        // backgroundAttachment: 'fixed', // Keep the background fixed while scrolling
        // Add other CSS properties here to style your container
        // width: '100%',
        // height: '100vh', // You can adjust the height as needed
    };

    return (
        <Fragment>
            <link rel="stylesheet" type="text/css" href="/src/css/docs.css" />

            <Header forceLightMenu={true} />
            <div id="content" className="container">
                <div className="row">
                    <div className="col-12 pt-4 pt-lg-0 d-lg-none">
                        {/* left nav */}
                        <button type="button" className="border" data-bs-toggle="collapse" data-bs-target="#leftnav" aria-controls="leftnav" aria-expanded="false" aria-label="Toggle navigation">
                            <svg xmlns="http://www.w3.org/2000/svg" className="text-primary me-2" width="32px" height="32px" fill="currentColor" viewBox="0 0 512 512"><line x1={80} y1={160} x2={432} y2={160} style={{fill: 'none', stroke: 'currentColor', strokeLinecap: 'round', strokeMiterlimit: 10, strokeWidth: '32px'}} /><line x1={80} y1={256} x2={432} y2={256} style={{fill: 'none', stroke: 'currentColor', strokeLinecap: 'round', strokeMiterlimit: 10, strokeWidth: '32px'}} /><line x1={80} y1={352} x2={432} y2={352} style={{fill: 'none', stroke: 'currentColor', strokeLinecap: 'round', strokeMiterlimit: 10, strokeWidth: '32px'}} /></svg>
                            Left Sidebar Menu
                        </button>
                    </div>

                    {/*left sidebar start*/}
                    <div className="col-12 col-lg-3 col-xl-2 bn-sidebar">
                        <nav className="collapse d-lg-block ps-lg-3 py-3" id="leftnav">
                            {Object.keys(articles).map((categoryId, key) => (
                                <div className="bn-toc-item" key={key}>
                                    <a className="bn-toc-link" href="#" data-bs-toggle="collapse" data-bs-target={`#collapse${key}`} aria-expanded="false" aria-controls={`collapse${key}`}>
                                        {categoryId}
                                    </a>
                                    <ul className="nav milyader collapse" id={`collapse${key}`} data-bs-parent="#leftnav">
                                        {articles[categoryId].map((article) => (
                                            <li key={article.id}><Link to={`/docs/${article.title.replace(/[^\w\s-]/g, '').toLowerCase().replace(/\s+/g, '-').replace(/\?/g, '')}`}>{article.title}</Link></li>
                                        ))}
                                    </ul>
                                </div>
                            ))}
                        </nav>
                    </div>
                    {/*Left sidebar end*/}

					{/*Right sidebar start*/}
					<div className="d-none d-xl-block col-xl-2 bn-toc">
						<ul id="right-nav" className="milyader ps-0 border-start text-muted">
                            {article.h2Tags.map((tag) => (
                                <li className="d-block">
                                    <a href={`#${tag.replace(/[^\w\s-]/g, '').toLowerCase().replace(/\s+/g, '-').replace(/\?/g, '')}`}>{tag}</a>
                                </li>
                            ))}
						</ul>
					</div>
					{/*Right sidebar end*/}

                    {/*Content start*/}
                    <style>{customStyles}</style>
                    <main className="col-12 col-lg-9 col-xl-8 px-md-3 ps-lg-5 pe-lg-4 bn-content pb-5 main-content">
                        {title && (
                            <div className="row">
                                <div
                                    dangerouslySetInnerHTML={{__html: article.body}}
                                />
                            </div>
                        )}
                        {!title && (
                            <div className="row">
                                <h1>BotScheduling Documents</h1>
                                {/*<p className="lead">Unlock the Power of BotScheduling with Expert Guides</p>*/}
                                <p>Check out our helpful guides and practical examples for smoothly integrating BotScheduling into your business.</p>

                                <div className="row ">
                                    <div className="col-12 mt-4 mb-4">
                                        <div className="card blog-one border-0 mb-4 mb-lg-0 rounded-3 overflow-hidden shadow-sm">
                                            <div className="card-body bg-secondary">
                                                <div className="card-title">
                                                    <h2 className="m-0 mb-2 text-white text-center">Search</h2>
                                                    <div className="text-white text-center">
                                                        Not sure where to start? Why not try a search?
                                                    </div>
                                                </div>
                                                <div>
                                                    <input type="email" className="form-control form-pill" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-4">
                                        <h3 className="text-secondary">Getting Started</h3>
                                        <ul className="list-group">
                                            {articles['Getting Started'] && articles['Getting Started'].map((article) => (
                                                <li className="list-group-item p-2" key={article.id}><Link to={`/docs/${article.title.replace(/[^\w\s-]/g, '').toLowerCase().replace(/\s+/g, '-').replace(/\?/g, '')}`}>{article.title}</Link></li>
                                            ))}
                                        </ul>
                                    </div>
                                    <div className="col-4">
                                        <h3 className="text-secondary">Workspaces</h3>
                                        <ul className="list-group">
                                            {articles['Workspaces'] && articles['Workspaces'].map((article) => (
                                                <li className="list-group-item p-2" key={article.id}><Link to={`/docs/${article.title.replace(/[^\w\s-]/g, '').toLowerCase().replace(/\s+/g, '-').replace(/\?/g, '')}`}>{article.title}</Link></li>
                                            ))}
                                        </ul>
                                    </div>
                                    <div className="col-4">
                                        <h3 className="text-secondary">Developers</h3>
                                        <ul className="list-group">
                                            <li className="list-group-item p-2"><Link to={`/docs/api`}>API</Link></li>
                                            <li className="list-group-item p-2"><Link to={`/docs/api`}>Manage Keys</Link></li>
                                            <li className="list-group-item p-2"><Link to={`/docs/api`}>Webhooks</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        )}
                    </main>
                    {/*Content end*/}
                </div>
            </div>
            <Footer />
        </Fragment>
    )
}

export default Docs;