import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

const PrivateRoute = () => {
	const isAuthenticated = localStorage.getItem('token');
	if (isAuthenticated && isAuthenticated === 'letmein') {
		return <Outlet />;
	} else {
		return <Navigate to="/auth"/>;
	}
}

export default PrivateRoute