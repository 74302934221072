import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import Header from "./fragments/Header";
import Footer from "./fragments/Footer";

export const NotFound = () => {
	return (
		<Fragment>
			<Header forceLightMenu={true} />

			<main id="content">
				<div id="overview" className="section py-6 mt-8 py-md-7 bg-white">
					<div className="container">
						<div className="row">
							{/*<div className="col-lg-6 px-6 px-md-8 px-lg-7 px-xl-8 text-center mb-5 mb-lg-0" data-aos="fade-up">*/}
							{/*	<img src="src/img-min/svg/404.svg" className="img-fluid" alt="Not found" />*/}
							{/*</div>*/}
							<div className="offset-lg-3 col-lg-6 align-self-center" data-aos="fade-up">
								<div className="max-box text-center text-lg-start">
									<h1 className="display-1 fw-bold">404</h1>
									<p className="lead mb-5">Oops! That page can&#39;t be found.<br />It looks like
										nothing was found at this location.</p>
									<Link to={"/"} className="btn btn-primary">
										<svg className="bi bi-house me-2" width="1.2rem" height="1.2rem"
											 viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
											<path fill-rule="evenodd"
												  d="M2 13.5V7h1v6.5a.5.5 0 00.5.5h9a.5.5 0 00.5-.5V7h1v6.5a1.5 1.5 0 01-1.5 1.5h-9A1.5 1.5 0 012 13.5zm11-11V6l-2-2V2.5a.5.5 0 01.5-.5h1a.5.5 0 01.5.5z"
												  clip-rule="evenodd"></path>
											<path fill-rule="evenodd"
												  d="M7.293 1.5a1 1 0 011.414 0l6.647 6.646a.5.5 0 01-.708.708L8 2.207 1.354 8.854a.5.5 0 11-.708-.708L7.293 1.5z"
												  clip-rule="evenodd"></path>
										</svg>
										Back to Home</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</main>

			<Footer />
		</Fragment>
	);
}

export default NotFound;